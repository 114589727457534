import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, A } from 'intdev-ui';

import { callApi } from '../../../../common/middlewares/apiMiddleware';
import { FORMDATA } from '../../../../common/constants/ajaxBodyTypes';
import { createTaskUrl } from '../../../../user_detail/apiUrls';
import { showErrorNotification } from '../../../../common/helpers/showNotification';
import AttachmentField from '../AttachmentField';

const footerStyle = {
    marginTop: 25,
};
const buttonStyle = {
    marginLeft: 10,
};

const enterKeyCode = 13;

const ERROR_MSG = 'К сожалению, произошла ошибка';


export class JiraTaskForm extends React.Component {
    static propTypes = {
        // eslint-disable-next-line
        currentOption: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onCloseModal: PropTypes.func.isRequired,
        onAddAnother: PropTypes.func.isRequired,
        setHasUnsaved: PropTypes.func.isRequired,
        canAddAttachments: PropTypes.bool.isRequired,
    };

    state = {
        summary: null,
        text: null,
        creatingTask: false,
        resultTask: null,
        errorMessage: null,
        errors: {},
        attachments: [],
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeydown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
    }

    getErrorType = fieldName => (this.state.errors[fieldName] ? 'error' : null);

    getFormData() {
        let text = this.state.text;
        if (this.props.currentOption.add_user_agent) {
            text = `${text}\n\nURL: ${window.location.href}\nUser-Agent: ${navigator.userAgent}`;
        }

        return {
            summary: this.state.summary,
            attachments: this.state.attachments,
            text,
        };
    }

    validateForm = () => {
        let isValid = true;
        for (const field of ['text', 'summary']) {
            if (!this.state[field]) {
                this.setState(prevState => ({ errors: {
                    ...prevState.errors,
                    [field]: 'Это поле обязательно',
                } }));
                isValid = false;
            }
        }
        return isValid;
    };

    handleKeydown = (event) => {
        if (
            !this.state.creatingTask
            && (event.metaKey || event.ctrlKey) && event.keyCode === enterKeyCode
        ) {
            this.handleSendButton();
        }
    };

    handleFieldChange = fieldName => (ev) => {
        this.props.setHasUnsaved(true);

        const value = ev.target.value;
        this.setState((prevState) => {
            const errors = Object.assign({}, prevState.errors);
            delete errors[fieldName];
            return {
                [fieldName]: value,
                errors,
                errorMessage: '',
            };
        });
    };

    handleSendButton = () => {
        const currentOption = this.props.currentOption;

        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        this.setState({
            creatingTask: true,
            errorMessage: '',
        });

        callApi(
            createTaskUrl(currentOption.id),
            'post',
            this.getFormData(),
            FORMDATA,
        ).then((data) => {
            this.props.setHasUnsaved(false);
            this.setState({
                creatingTask: false,
                resultTask: data.task,
            });
        }).catch((data) => {
            showErrorNotification(data.error);

            this.setState({
                creatingTask: false,
                errorMessage: ERROR_MSG,
            });
        });
    };

    handleAddAnother = () => {
        this.setState({ resultTask: null });
        this.props.onAddAnother();
    };

    handleUpdateAttachments = attachments => this.setState({ attachments });

    renderResult(resultTask) {
        return (
            <div className="task-form-result-container">
                <h1 className="task-form-result-header">
                    {
                        resultTask.url ?
                            <Fragment>
                                Таск&nbsp;
                                <A
                                    className="task-form-result-link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={ resultTask.url }
                                >
                                    { resultTask.key }
                                </A>
                                &nbsp;создан
                            </Fragment>
                            :
                            'Таск создан'
                    }

                </h1>
                <Button
                    onClick={ this.handleAddAnother }
                    label="Создать еще один"
                />
                <Button
                    style={ buttonStyle }
                    onClick={ this.props.onCloseModal }
                    label="ОК"
                    primary
                />
            </div>
        );
    }

    render() {
        const resultTask = this.state.resultTask;
        const description = this.props.currentOption.description;

        if (resultTask) {
            return this.renderResult(resultTask);
        }

        return (
            <div>
                { !!description &&
                    <div
                        dangerouslySetInnerHTML={ { __html: description } }
                        style={ { marginBottom: '15px' } }
                    />
                }
                <div className="form-field_el" style={ { marginBottom: '15px' } }>
                    <label>Заголовок</label>
                    <TextField
                        fullWidth
                        disabled={ this.state.creatingTask }
                        onChange={ this.handleFieldChange('summary') }
                        name="summary"
                        validationState={ this.getErrorType('summary') }
                        validationText={ this.state.errors.summary }
                    />
                </div>
                <div className="form-field_el task-form-textarea">
                    <label>Описание задачи</label>
                    <TextField
                        fullWidth
                        multiLine
                        disabled={ this.state.creatingTask }
                        rows={ 5 }
                        rowsMax={ 15 }
                        onChange={ this.handleFieldChange('text') }
                        name="text"
                        validationState={ this.getErrorType('text') }
                        validationText={ this.state.errors.text || this.state.errorMessage }
                    />
                </div>
                {
                    this.props.canAddAttachments &&
                    <div className="form-fields_el">
                        <AttachmentField
                            disabled={ this.state.creatingTask }
                            files={ this.state.attachments }
                            onUpdateFiles={ this.handleUpdateAttachments }
                            accept=".jpg,.png,.jpeg,.mov"
                        />
                    </div>
                }

                <div style={ footerStyle }>
                    <Button
                        label="Отправить"
                        onClick={ this.handleSendButton }
                        disabled={ this.state.creatingTask }
                        primary
                    />
                    <Button
                        style={ buttonStyle }
                        label="Отмена"
                        disabled={ this.state.creatingTask }
                        onClick={ this.props.onCancel }
                    />
                </div>
            </div>
        );
    }
}
