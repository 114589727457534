import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ResultItemText extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        caption: PropTypes.string,
    };

    static defaultProps = {
        caption: '',
    };

    render() {
        const { text, caption } = this.props;

        return (
            <div style={ { flex: 1, lineHeight: '1em' } }>
                <span style={ { fontSize: 14, color: '000', fontFamily: 'SF Pro Display, sans-serif' } }>{ text }</span><br />
                <span style={ { color: '#949494', fontSize: 14, fontFamily: 'SF Pro Display, sans-serif' } }>{ caption }</span>
            </div>
        );
    }
}
