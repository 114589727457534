import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { A } from 'intdev-ui';

function AuthError(props) {
    const url = `/login/?next=${encodeURIComponent(props.pathname)}`;
    return (
        <div id="auth-error-message" style={ { display: props.isAuthErrorShown ? 'flex' : 'none' } }>
            <div id="auth-error-message__text">
                При выполнении запроса произошла ошибка авторизации.
                Возможно, необходимо повторно ввести логин и пароль.
                <A href={ url } id="auth-error-message__login-link">Перейти на страницу авторизации</A>
            </div>
            <div>
                <span onClick={ props.onHide } id="auth-error-message__close" />
            </div>
        </div>
    );
}

AuthError.propTypes = {
    pathname: PropTypes.string,
    onHide: PropTypes.func.isRequired,
    isAuthErrorShown: PropTypes.bool,
};

AuthError.defaultProps = {
    pathname: '/',
    isAuthErrorShown: false,
};

function mapStateToProps(state) {
    return {
        isAuthErrorShown: state.headerReducer.isAuthErrorShown,
    };
}

function onHide() {
    return {
        type: '@@authError/VISIBLE',
        visible: false,
    };
}

export default connect(mapStateToProps, { onHide })(AuthError);
