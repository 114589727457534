import React from 'react';
import PropTypes from 'prop-types';
import classes from './SimpleItem.module.scss';

export const SimpleItem = ({ item, handleCloseMenu }) => (
    <div className={ classes.link }>
        <a
            href={ item.url }
            onClick={ handleCloseMenu }
            target={ item.newtab ? '_blank' : '_self' }
            style={ { textDecoration: 'none' } }
        >
            <div className={ classes.container }>
                <div className={ classes.icon }>
                    {item.image && <img src={ item.image } alt="" className={ classes.icon } />}
                </div>
                <div className={ classes.title }>
                    {item.name}
                    {
                        item.custom_contents ? (
                            <span
                                dangerouslySetInnerHTML={ { __html: item.custom_contents } }
                            />
                        ) : null
                    }
                </div>
            </div>
        </a>
    </div>

);

SimpleItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        url: PropTypes.string,
        newtab: PropTypes.bool,
        perm: PropTypes.string,
        custom_contents: PropTypes.string,
    }).isRequired,
    handleCloseMenu: PropTypes.func,
};

SimpleItem.defaultProps = {
    handleCloseMenu: null,
};
