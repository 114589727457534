import React from 'react';
import PropTypes from 'prop-types';
import { Button, ConfirmButton } from 'intdev-ui';
import IconFaButton from '../../../common/components/IconFaButton';


const styles = {
    actionButtonStyle: {
        marginLeft: '5px',
    },
    actionButtonWrapper: {
        display: 'flex',
        alignItems: 'center',
        width: '100px',
    },
    button: {
        width: 300,
    },
    wrapperLine: {
        display: 'flex',
        justifyContent: 'center',
    },
};


export class OptionButton extends React.Component {
    static propTypes = {
        /* eslint-disable-next-line */
        option: PropTypes.object.isRequired,
        onDelete: PropTypes.func.isRequired,
        onOpenAddTask: PropTypes.func.isRequired,
        onOpenEditOption: PropTypes.func.isRequired,
        canConfig: PropTypes.bool.isRequired,
    };

    handleOpen = () => { this.props.onOpenAddTask(this.props.option); };

    handleEdit = () => { this.props.onOpenEditOption(this.props.option); };

    handleDelete = () => { this.props.onDelete(this.props.option); };

    render() {
        const option = this.props.option;
        return (

            <div style={ { marginBottom: 16 } }>
                <div style={ styles.wrapperLine }>
                    { this.props.canConfig ? <span style={ styles.actionButtonWrapper } /> : null }
                    <Button
                        multiLine
                        label={ option.title }
                        disabled={ this.props.option.isDeleting }
                        onClick={ this.handleOpen }
                        primary
                        style={ styles.button }
                    />

                    { this.props.canConfig &&
                        <span style={ styles.actionButtonWrapper }>
                            <IconFaButton
                                iconClass="fa-pencil"
                                title="Редактировать кнопку"
                                onClick={ this.handleEdit }
                                style={ styles.actionButtonStyle }
                            />

                            <ConfirmButton
                                dialogProps={ { contentStyle: { width: '420px' } } }
                                dialogText="Вы действительно хотите удалить кнопку?"
                                onClick={ this.handleDelete }
                                buttonElement={
                                    <IconFaButton
                                        iconClass="fa-trash"
                                        title="Удалить кнопку"
                                        style={ styles.actionButtonStyle }
                                    />
                                }
                            />
                        </span>
                    }
                </div>
            </div>
        );
    }
}
