import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { DefaultResultItem } from './DefaultResultItem';
import { UserResultItem } from './UserResultItem';

const SearchResultItemComponent = props => {
    switch (props.contentType) {
        case 'users.userprofile':
            return <UserResultItem canCall={ props.features.calls } { ...props } />;

        default:
            return <DefaultResultItem { ...props } />;
    }
};

SearchResultItemComponent.propTypes = {
    contentType: PropTypes.string.isRequired,
    features: PropTypes.shape(),
};

SearchResultItemComponent.defaultProps = {
    features: { calls: false },
};


function mapStateToProps(state) {
    return {
        features: state.features,
    };
}

export const SearchResultItem = connect(mapStateToProps)(SearchResultItemComponent);
