import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { MenuPropTypes } from '@/base_page/constants/constants';
import { SimpleItem } from '@/base_page/components/BaseMenu/SimpleItem/SimpleItem';
import { ExpandableItem } from '@/base_page/components/BaseMenu/ExpandableItem/ExpandableItem';
import { filterMenuByStructure } from '@/base_page/helpers';
import classes from './BaseMenu.module.scss';

export const BaseMenu = ({ navigationMenu, handleCloseMenu }) => {
    const [currentMenu, setCurrentMenu] = useState(null);

    const handleExpandMenu = (name) => {
        setCurrentMenu(currentMenu === name ? null : name);
    };

    const filtredMenu = filterMenuByStructure(navigationMenu);

    return (
        <div className={ classes.container }>
            {filtredMenu.map(item => (item.includes_submenu
                ? (
                    <ExpandableItem
                        key={ item.name }
                        isExpanded={ currentMenu === item.name }
                        handleClick={ handleExpandMenu }
                        item={ item }
                        handleCloseMenu={ handleCloseMenu }
                    />
                ) : <SimpleItem key={ item.name } item={ item } handleCloseMenu={ handleCloseMenu } />))}
        </div>
    );
};

BaseMenu.propTypes = {
    handleCloseMenu: PropTypes.func,
    navigationMenu: PropTypes.arrayOf(PropTypes.shape(MenuPropTypes)).isRequired,
};

BaseMenu.defaultProps = {
    handleCloseMenu: null,
};
