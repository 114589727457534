import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CallPopover } from '../sys_call/CallPopover';

const STYLE = {
    callButton: {
        cursor: 'pointer',
    },
};

export class CallButton extends Component {
    static propTypes = {
        label: PropTypes.node.isRequired,
        isOpenPopover: PropTypes.bool.isRequired,
        requestCall: PropTypes.func.isRequired,
        onRequestClose: PropTypes.func.isRequired,
        devices: PropTypes.arrayOf(PropTypes.shape({
            model: PropTypes.string,
            name: PropTypes.string.isRequired,
            desc: PropTypes.string,
        })).isRequired,
        popoverProps: PropTypes.shape(),
        status: PropTypes.oneOf(['ready', 'inProgress', 'error']).isRequired,
    };

    static defaultProps = {
        popoverProps: {},
    };

    state = {
        anchorEl: null,
    };

    handleClick = (event) => {
        event.preventDefault();
        this.setState({
            anchorEl: event.currentTarget,
        });
        this.props.requestCall();
    };

    render() {
        let callText;
        if (this.props.status !== 'ready') {
            callText = this.props.label;
        } else {
            callText = <span style={ STYLE.callButton } onClick={ this.handleClick }>{ this.props.label }</span>;
        }
        return (
            <span>
                { callText }
                <CallPopover
                    anchorEl={ this.state.anchorEl }
                    devices={ this.props.devices }
                    onRequestClose={ this.props.onRequestClose }
                    isOpenPopover={ this.props.isOpenPopover }
                    requestCall={ this.props.requestCall }
                    popoverProps={ this.props.popoverProps }
                />
            </span>
        );
    }
}
