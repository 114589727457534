import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { showErrorNotification, showNotification } from '../../../common/helpers/showNotification';
import MrgIcon from '../../../common/components/MrgIcon';


const FILES_LIMIT = 5;
const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5Mb

const dropzoneStyle = {
    default: {
        width: '100%',
        height: '80px',
        color: '#a6a6a6',
        fontSize: '15px',
        textDecoration: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        marginTop: '10px',
        border: '2px dashed rgb(240, 240, 240)',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    active: {
        backgroundColor: 'rgba(240, 240, 240, .7)',
    },
};


class AttachmentField extends Component {
    static propTypes = {
        accept: PropTypes.string,
        disabled: PropTypes.bool,
        files: PropTypes.arrayOf(PropTypes.shape({})),
        onUpdateFiles: PropTypes.func.isRequired,
    };
    static defaultProps = {
        accept: '.jpg,.png,.jpeg',
        disabled: false,
        files: [],
    };

    get attachments() {
        const { files } = this.props;
        return (
            <div className="jt-form__attachments-list">
                { files.map((file, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="jt-form__attachments-list-item" key={ index }>
                        <div
                            className="jt-form__attachments-list-item-action"
                            onClick={ this.handleRemoveFile(index) }
                        >
                            <MrgIcon className="icon_actions--close_round" />
                        </div>
                        <div className="jt-form__attachments-list-item-image-container">
                            { this.getPreview(file) }
                        </div>
                        <div className="jt-form__attachments-list-item-name">{ file.name }</div>
                    </div>
                )) }
            </div>
        );
    }

    getPreview = (file) => {
        const fileType = file.type.split('/')[0];
        switch (fileType) {
            case 'image':
                return <img className="jt-form__attachments-list-item-img" src={ file.preview } />;
            case 'video':
                return <img className="jt-form__attachments-list-item-img" src="/static/img/filetypes/video.svg" />;
            default:
                return <img className="jt-form__attachments-list-item-img" src="/static/img/filetypes/other.svg" />;
        }
    };

    handleOnDrop = (accepted) => {
        if (!accepted.length) {
            showErrorNotification('Недопустимый тип или размер файла');
            return;
        }
        const files = [...this.props.files, ...accepted];
        if (files.length > FILES_LIMIT) {
            showNotification({
                level: 'warning',
                message: `Можно приложить не более ${FILES_LIMIT} файлов`,
            });
        }
        this.props.onUpdateFiles(files.slice(0, FILES_LIMIT));
    };

    handleRemoveFile = index => () => {
        const files = [...this.props.files];
        files.splice(index, 1);
        this.props.onUpdateFiles(files);
    };

    render() {
        const {
            accept, disabled, files,
        } = this.props;
        return (
            <div>
                { files.length > 0 && this.attachments }
                { files.length < FILES_LIMIT &&
                    <Dropzone
                        accept={ accept }
                        onDrop={ this.handleOnDrop }
                        style={ dropzoneStyle.default }
                        activeStyle={ dropzoneStyle.active }
                        disabled={ disabled }
                        maxSize={ MAX_FILE_SIZE }
                        multiple
                    >
                        <span>Перетащи изображения или видео в эту область или нажми для выбора файлов</span>
                    </Dropzone>
                }
            </div>
        );
    }
}

export default AttachmentField;
