export const filterMenuByPerms = ({
    menu,
    perms,
}) => {
    const filteredHighOrderedMenuItems = menu.filter(menu => (!menu.perm || perms.includes(menu.perm))
        && menu.feature !== false);

    const filteredMenuByPerms = filteredHighOrderedMenuItems.map((menuItem) => {
        const filteredSubmenuItems = menuItem.submenu_items.filter(item => ((!item.perm || perms.includes(item.perm))
            && item.feature !== false));
        return {
            ...menuItem,
            submenu_items: filteredSubmenuItems,
        };
    });

    const filteredMenu = filteredMenuByPerms.filter(category => (!category.includes_submenu)
        || (category.includes_submenu && !!category.submenu_items.length));

    return filteredMenu;
};

export const filterMenuByStructure = (arrayOfMenu) => {
    let filtredMenu = arrayOfMenu;
    const hasDuplicateStructure = items => (
        items.some(i => i.url === '/structure/truncated/')
        && items.some(i => i.url === '/structure/')
    );
    if (hasDuplicateStructure(arrayOfMenu)) {
        filtredMenu = arrayOfMenu.filter(i => i.url !== '/structure/truncated/');
    }
    return filtredMenu;
};
