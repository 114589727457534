import React from 'react';
import PropTypes from 'prop-types';
import { Button, IUIPropTypes } from 'intdev-ui';

import { TaskFormDialog } from './components/TaskFormDialog';
import { callApi } from '../../common/middlewares/apiMiddleware';
import { getOptionsUrl, getButtonOptionsUrl, getButtonByShortname } from '../../user_detail/apiUrls';
import { showNotification } from '../../common/helpers/showNotification';
import './styles.css';
import { clearLocalStorageText, getLocalStorageText } from '../../common/helpers/localStorage';
import { JIRA_TASK_KEY, JIRA_TASK_USER_FORM } from './constants';
import { dispatchMetricsEvent } from '../../common/helpers/metrics';


/**
 * Кнопка для постановки задач Jira и отправки писем.
 * Принимает либо userId пользователя, на странице которого кнопка,
 * либо имя кнопки buttonShortname.
 *
 * https://confluence.mail.ru/pages/viewpage.action?pageId=163722389
 */


const styles = {
    label: {
        padding: '0',
    },
};

class JiraTaskButton extends React.Component {
    static propTypes = {
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        buttonShortname: PropTypes.string,
        primary: PropTypes.bool,
        buttonLabel: PropTypes.string,
        buttonStyle: IUIPropTypes.style,
        buttonLabelStyle: IUIPropTypes.style,
        metricsEventName: PropTypes.string,
        welcomeText: PropTypes.string,
        optionsListHeader: PropTypes.string,
    };

    static defaultProps = {
        userId: null,
        buttonShortname: null,
        primary: true,
        buttonLabel: 'Поставить задачу',
        optionsListHeader: 'Выбери задачу',
        metricsEventName: '',
        welcomeText: null,
        buttonStyle: {},
        buttonLabelStyle: styles.label,
    };

    state = {
        modalIsOpen: false,
        loadingOptions: false,
        projectOptions: [],
        componentOptions: [],
        issueTypeOptions: [],
        options: {},
        buttonId: null,
        targetUser: null,
        canAddOptions: false,
        jiraAuthorized: false,
        jiraAuthPageUrl: null,
        allowedTaskTypes: {},
    };

    componentDidMount() {
        this.tryReopenAfterRedirect();
    }

    getOptions = () => {
        this.setState({ loadingOptions: true });

        const shortName = this.props.buttonShortname;
        let optionsUrl;
        if (this.props.userId) {
            // Кнопка со страницы юзера
            optionsUrl = getOptionsUrl(this.props.userId);
        } else {
            optionsUrl = getButtonOptionsUrl(shortName);
        }

        const getOptionsPromise = callApi(optionsUrl, 'get').then((data) => {
            const emailOptions = {};
            Object.keys(data.email_options || {}).forEach((optionKey) => {
                emailOptions[optionKey] = {
                    ...data.email_options[optionKey],
                    isEmailOption: true,
                };
            });

            this.setState({
                canAddOptions: data.can_add_options,
                taskOptions: data.task_options,
                emailOptions,
                targetUser: data.target_user,
                projectOptions: data.project_options,
                jiraAuthorized: data.jira_authorized,
                jiraAuthPageUrl: data.jira_auth_page_url,
                allowedTaskTypes: data.allowed_task_types,
                modalIsOpen: true,
            });
        });

        const getButtonIdPromise = shortName ? callApi(getButtonByShortname(shortName), 'get').then((data) => {
            this.setState({ buttonId: data.button });
        }) : Promise.resolve(null);

        Promise.all([getOptionsPromise, getButtonIdPromise]).then(() => {
            this.setState({ loadingOptions: false });
        }).catch(() => {
            showNotification({ message: 'Создание задач в настоящее время недоступно', level: 'error' });
            this.setState({ loadingOptions: false });
        });
    };

    getWelcomeText = () => {
        if (!this.state.allowedTaskTypes) {
            return null;
        }
        if (this.props.welcomeText) {
            return this.props.welcomeText;
        } if (this.props.userId && this.state.allowedTaskTypes.jira) {
            return `Типы задач еще не добавлены. С их помощью ты можешь дать коллегам
            возможность ставить тебе задачи прямо из профиля интранета. Для этого
            понадобится указать проект и компонент Jira.`;
        }
        return 'Типы задач еще не добавлены.';
    };

    setIsDeleting = (option, isDeleting) => {
        this.updateOptionData({
            ...option,
            isDeleting,
        });
    };

    tryReopenAfterRedirect() {
        // Пытаемся переоткрыть окно после редиректа из авторизации в джире
        const showJiraTaskParam = getLocalStorageText(JIRA_TASK_KEY) || '';
        let modalToBeOpened = false;
        const { buttonShortname } = this.props;
        if (buttonShortname) {
            modalToBeOpened = showJiraTaskParam === buttonShortname;
        } else {
            modalToBeOpened = showJiraTaskParam === JIRA_TASK_USER_FORM;
        }
        if (modalToBeOpened) {
            this.handleOpenModal();
            clearLocalStorageText(JIRA_TASK_KEY);
        }
    }

    updateOptionData = (optionData) => {
        this.setState((prevState) => {
            let optionsKey = null;
            if (optionData.isEmailOption) {
                optionsKey = 'emailOptions';
            } else {
                optionsKey = 'taskOptions';
            }

            let newOptionData = null;
            if (optionData.id in this.state[optionsKey]) {
                newOptionData = Object.assign(
                    prevState[optionsKey][optionData.id], optionData,
                );
            } else {
                newOptionData = optionData;
            }

            return {
                [optionsKey]: {
                    ...prevState[optionsKey],
                    [optionData.id]: newOptionData,
                },
            };
        });
    };

    handleOpenModal = () => {
        this.setState({ modalIsOpen: true });
        this.getOptions();
        const { metricsEventName } = this.props;
        if (metricsEventName) {
            dispatchMetricsEvent(metricsEventName);
        }
    };

    handleCloseModal = () => {
        this.setState({ modalIsOpen: false });
    };

    render() {
        return (
            <>
                <Button
                    fullWidth
                    labelStyle={ this.props.buttonLabelStyle }
                    style={ this.props.buttonStyle }
                    label={ this.props.buttonLabel }
                    onClick={ this.handleOpenModal }
                    primary={ this.props.primary }
                />
                <TaskFormDialog
                    modalIsOpen={ this.state.modalIsOpen }
                    canAddOptions={ this.state.canAddOptions }
                    targetUser={ this.state.targetUser }
                    buttonId={ this.state.buttonId }
                    buttonShortname={ this.props.buttonShortname }
                    taskOptions={ this.state.taskOptions }
                    emailOptions={ this.state.emailOptions }
                    projectOptions={ this.state.projectOptions }
                    loadingOptions={ this.state.loadingOptions }
                    jiraAuthPageUrl={ this.state.jiraAuthPageUrl }
                    jiraAuthorized={ this.state.jiraAuthorized }
                    allowedTaskTypes={ this.state.allowedTaskTypes }
                    metricsEventName={ this.props.metricsEventName }
                    getOptions={ this.getOptions }
                    onCloseModal={ this.handleCloseModal }
                    updateOptionData={ this.updateOptionData }
                    welcomeText={ this.getWelcomeText() }
                    optionsListHeader={ this.props.optionsListHeader }
                    setIsDeleting={ this.setIsDeleting }
                />
            </>
        );
    }
}

export default JiraTaskButton;
