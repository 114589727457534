export const shouldCreateNewLabelOption = ({ keyCode }) => {
    switch (keyCode) {
        case 9: // TAB
        case 32: // SPACE
        case 13: // ENTER
            return true;
        case 188:
        case 190:
            return event.key === ',';
        default:
            return false;
    }
};

export const getValidationErrors = (resp, formFieldNames) => {
    const validationErrors = {};
    const validationErrorNames = Object.keys(resp)
        .filter(fieldName => formFieldNames.indexOf(fieldName) > -1);
    validationErrorNames.forEach((key) => {
        validationErrors[key] = resp[key][0];
    });
    return validationErrors;
};
