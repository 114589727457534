import React from 'react';
import PropTypes from 'prop-types';
import classes from './MenuToggleButton.module.scss';

export const MenuToggleButton = ({ onClick, disabled }) => (
    <button
        type="button"
        disabled={ disabled }
        className={ classes.btn }
        onClick={ onClick }
    >
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="14" viewBox="0 0 20 14" fill="none">
            <path
                d="M1.5 11.9999H12.5004M1.5 1.99994H12.5"
                stroke={ disabled ? '#B4B4BD' : '#7770D3' }
                strokeWidth="2"
                strokeLinecap="round"
            />
            <path
                d="M1.5 6.99994H9.5M17.5 2.99994L13.5707 6.92923C13.5317 6.96828 13.5317 7.0316 13.5707 7.07065L17.5 10.9999"
                stroke={ disabled ? '#898995' : '#3E36B0' }
                strokeWidth="2"
                strokeLinecap="round"
            />
        </svg>
    </button>
);

MenuToggleButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
};

MenuToggleButton.defaultProps = {
    disabled: false,
};
