import React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, Button, Select, Spinner,
} from 'intdev-ui';

import { OptionButton } from './OptionButton';
import { JiraOptionForm } from './forms/JiraOptionForm';
import { EmailOptionForm } from './forms/EmailOptionForm';
import { JiraTaskForm } from './forms/JiraTaskForm';
import { EmailForm } from './forms/EmailForm';
import { callApi } from '../../../common/middlewares/apiMiddleware';
import { showNotification } from '../../../common/helpers/showNotification';
import {
    deleteOptionUrl,
    updateOptionUrl,
    createOptionUrl,
    createEmailOptionUrl,
    updateEmailOptionUrl, deleteEmailOptionUrl,
} from '../../../user_detail/apiUrls';
import { ConfirmCloseDialog } from './ConfirmCloseDialog';
import { setLocalStorageText } from '../../../common/helpers/localStorage';
import { JIRA_TASK_KEY, JIRA_TASK_USER_FORM } from '../constants';
import { dispatchMetricsEvent } from '../../../common/helpers/metrics';


const styles = {
    wrapper: {
        textAlign: 'center',
    },
    addButton: {
        width: 300,
        marginTop: 16,
    },
    spinner: {
        margin: '16px',
    },
};


const ADD_TASK = 1;
const ADD_OPTION = 2;
const EDIT_OPTION = 3;


const TASK_TYPE_JIRA = 1;
const TASK_TYPE_EMAIL = 2;
const taskTypeOptionJira = {
    value: TASK_TYPE_JIRA,
    label: 'Таск Jira',
};
const taskTypeOptionEmail = {
    value: TASK_TYPE_EMAIL,
    label: 'Отправка письма',
};


export class TaskFormDialog extends React.Component {
    static propTypes = {
        modalIsOpen: PropTypes.bool.isRequired,
        canAddOptions: PropTypes.bool.isRequired,
        // eslint-disable-next-line
        targetUser: PropTypes.object,
        // eslint-disable-next-line
        taskOptions: PropTypes.object,
        // eslint-disable-next-line
        emailOptions: PropTypes.object,
        jiraAuthPageUrl: PropTypes.string,
        jiraAuthorized: PropTypes.bool,
        projectOptions: PropTypes.arrayOf(PropTypes.object),
        loadingOptions: PropTypes.bool.isRequired,
        metricsEventName: PropTypes.string,
        getOptions: PropTypes.func.isRequired,
        onCloseModal: PropTypes.func.isRequired,
        updateOptionData: PropTypes.func.isRequired,
        welcomeText: PropTypes.string.isRequired,
        buttonId: PropTypes.number,
        buttonShortname: PropTypes.string,
        optionsListHeader: PropTypes.string.isRequired,
        setIsDeleting: PropTypes.func.isRequired,
        allowedTaskTypes: PropTypes.shape({
            jira: PropTypes.bool,
            email: PropTypes.bool,
        }),
    };

    static defaultProps = {
        allowedTaskTypes: null,
        buttonId: null,
        buttonShortname: '',
        jiraAuthPageUrl: null,
        jiraAuthorized: null,
        metricsEventName: '',
        projectOptions: null,
        targetUser: null,
        taskOptions: null,
    };

    state = {
        hasUnsaved: false,
        currentOption: null,
        chosenAction: null,
        confirmModalIsOpen: false,
        taskType: null,
    };

    getTaskTypeOptions = () => {
        const { allowedTaskTypes } = this.props;
        if (!allowedTaskTypes) {
            return null;
        }
        const options = [];
        if (allowedTaskTypes.jira) {
            options.push(taskTypeOptionJira);
        }
        if (allowedTaskTypes.email) {
            options.push(taskTypeOptionEmail);
        }
        return options;
    };


    setHasUnsaved = (value) => {
        this.setState({ hasUnsaved: value });
    };

    closeModal = () => {
        this.setState({
            hasUnsaved: false,
            currentOption: null,
            chosenAction: null,
        });
        this.props.onCloseModal();
    };

    handleDeleteOption = (option) => {
        if (option.isDeleting) {
            return;
        }
        let url = null;
        if (option.isEmailOption) {
            url = deleteEmailOptionUrl(option.id);
        } else {
            url = deleteOptionUrl(option.id);
        }

        this.props.setIsDeleting(option, true);
        callApi(url, 'delete').then(() => {
            this.props.getOptions();
            this.props.setIsDeleting(option, false);
        }).catch(() => {
            this.props.setIsDeleting(option, false);
            return showNotification({
                message: 'Не удалось удалить действие',
                level: 'error',
            });
        });
    };

    handleOpenEditOption = (option) => {
        if (option.isDeleting) {
            return;
        }
        this.setState({
            currentOption: option,
            chosenAction: EDIT_OPTION,
        });
    };

    handleOpenAddTask = (option) => {
        if (option.isDeleting) {
            return;
        }

        const {
            jiraAuthorized, jiraAuthPageUrl, buttonShortname, metricsEventName,
        } = this.props;
        if (metricsEventName) {
            dispatchMetricsEvent(`${metricsEventName}-option-form`);
        }

        const jiraAuthRequired = !option.isEmailOption && !jiraAuthorized && jiraAuthPageUrl;
        if (jiraAuthRequired) {
            if (buttonShortname) {
                setLocalStorageText(buttonShortname, JIRA_TASK_KEY);
            } else {
                setLocalStorageText(JIRA_TASK_USER_FORM, JIRA_TASK_KEY);
            }
            window.location.replace(jiraAuthPageUrl);
        } else {
            this.setState({
                currentOption: option,
                chosenAction: ADD_TASK,
            });
        }
    };

    handleOpenAddOption = () => {
        this.setState({ chosenAction: ADD_OPTION });
    };

    handleCancel = () => {
        this.setState({
            hasUnsaved: false,
            chosenAction: null,
            currentOption: null,
        });
    };

    handleCloseModal = () => {
        if (this.state.hasUnsaved) {
            this.setState({ confirmModalIsOpen: true });
        } else {
            this.closeModal();
        }
    };

    handleCloseConfirmModal = () => {
        this.setState({ confirmModalIsOpen: false });
    };

    handleAddAnotherTask = () => {
        let currentOption = null;
        if (Object.keys(this.props.taskOptions).length === 1) {
            currentOption = Object.values(this.props.taskOptions)[0];
            this.handleOpenAddTask(currentOption);
        } else {
            this.setState({
                chosenAction: null,
                currentOption: null,
            });
        }
    };

    handleSelectTaskType = (value) => {
        this.setState({ taskType: value });
    };

    updateOptionData = (optionData) => {
        this.props.updateOptionData(optionData);
        this.setState({
            currentOption: null,
            chosenAction: null,
        });
    };

    renderOptionAddForm() {
        const taskTypeOptions = this.getTaskTypeOptions();
        if (!taskTypeOptions) {
            return null;
        }

        const taskType = (this.state.taskType && this.state.taskType.value) || taskTypeOptions[0];

        const optionForm = taskType === TASK_TYPE_EMAIL
            ? (
                <EmailOptionForm
                    submitUrl={ createEmailOptionUrl }
                    targetUser={ this.props.targetUser }
                    buttonId={ this.props.buttonId }
                    updateOptionData={ this.updateOptionData }
                    onCancel={ this.handleCancel }
                />
            ) : (
                <JiraOptionForm
                    submitUrl={ createOptionUrl }
                    projectOptions={ this.props.projectOptions }
                    targetUser={ this.props.targetUser }
                    buttonId={ this.props.buttonId }
                    updateOptionData={ this.updateOptionData }
                    onCancel={ this.handleCancel }
                />
            );

        return (
            <>
                { taskTypeOptions.length > 1 && (
                    <Select
                        options={ taskTypeOptions }
                        value={ taskType }
                        onChange={ this.handleSelectTaskType }
                        clearable={ false }
                    />
                ) }
                { optionForm }
            </>
        );
    }

    renderOptionEditForm() {
        if (this.state.currentOption.isEmailOption) {
            const optionData = this.props.emailOptions[this.state.currentOption.id];
            return (
                <EmailOptionForm
                    optionId={ this.state.currentOption.id }
                    title={ optionData.title }
                    description={ optionData.description }
                    emails={ optionData.emails }
                    targetUser={ this.props.targetUser }
                    buttonId={ this.props.buttonId }
                    allow_fill_subject={ optionData.allow_fill_subject }
                    subject={ optionData.subject }
                    send_to_author={ optionData.send_to_author }
                    submitUrl={ updateEmailOptionUrl(optionData.id) }
                    updateOptionData={ this.updateOptionData }
                    onCancel={ this.handleCancel }
                />
            );
        }

        const optionData = this.props.taskOptions[this.state.currentOption.id];
        return (
            <JiraOptionForm
                optionId={ this.state.currentOption.id }
                title={ optionData.title }
                description={ optionData.description }
                project={ optionData.project }
                component={ optionData.component }
                issueType={ optionData.issue_type }
                labels={ optionData.labels }
                addUserAgent={ optionData.add_user_agent }
                fallbackEmail={ optionData.fallback_email }
                targetUser={ this.props.targetUser }
                buttonId={ this.props.buttonId }
                submitUrl={ updateOptionUrl(optionData.id) }
                projectOptions={ this.props.projectOptions }
                updateOptionData={ this.updateOptionData }
                onCancel={ this.handleCancel }
            />
        );
    }

    renderOptionsList() {
        let welcomeText = '';

        const taskOptionsArray = Object.values(this.props.taskOptions || {});
        const emailOptionsArray = Object.values(this.props.emailOptions || {});
        const optionsArray = taskOptionsArray.concat(emailOptionsArray);

        if (this.props.canAddOptions && (!this.props.loadingOptions && !optionsArray.length)) {
            welcomeText = this.props.welcomeText;
        }

        const options = optionsArray.map(
            option => (
                <OptionButton
                    key={ option.id }
                    canConfig={ option.can_config }
                    option={ option }
                    onDelete={ this.handleDeleteOption }
                    onOpenEditOption={ this.handleOpenEditOption }
                    onOpenAddTask={ this.handleOpenAddTask }
                />
            ),
        );

        return (
            <div style={ styles.wrapper }>
                <div>
                    { welcomeText }
                </div>

                { this.props.loadingOptions
                    ? <div style={ styles.spinner }><Spinner /></div>
                    : options }
                { this.props.canAddOptions && (
                    <Button
                        style={ styles.addButton }
                        label="Добавить кнопку действия"
                        onClick={ this.handleOpenAddOption }
                    />
                )}
            </div>
        );
    }

    renderAddTaskForm() {
        if (this.state.currentOption.isEmailOption) {
            return (
                <EmailForm
                    currentOption={ this.state.currentOption }
                    onCancel={ this.handleCancel }
                    onCloseModal={ this.handleCloseModal }
                    onAddAnother={ this.handleAddAnotherTask }
                    setHasUnsaved={ this.setHasUnsaved }
                    canAddAttachments
                />
            );
        }

        return (
            <JiraTaskForm
                currentOption={ this.state.currentOption }
                onCancel={ this.handleCancel }
                onCloseModal={ this.handleCloseModal }
                onAddAnother={ this.handleAddAnotherTask }
                setHasUnsaved={ this.setHasUnsaved }
                canAddAttachments
            />
        );
    }

    render() {
        let header;
        let contents;

        if (this.state.chosenAction === EDIT_OPTION && this.state.currentOption) {
            header = 'Редактирование кнопки действия';
            contents = this.renderOptionEditForm();
        } else if (this.state.chosenAction === ADD_OPTION) {
            header = 'Добавление кнопки действия';
            contents = this.renderOptionAddForm();
        } else if (this.state.chosenAction === ADD_TASK && this.state.currentOption) {
            header = this.state.currentOption.title;
            contents = this.renderAddTaskForm();
        } else {
            header = this.props.optionsListHeader;
            contents = this.renderOptionsList();
        }

        return (
            <div>
                <Dialog
                    title={ header }
                    open={ this.props.modalIsOpen }
                    onRequestClose={ this.handleCloseModal }
                    autoScrollBodyContent
                >
                    { contents }
                </Dialog>

                <ConfirmCloseDialog
                    confirmModalIsOpen={ this.state.confirmModalIsOpen }
                    closeModal={ this.closeModal }
                    onCloseConfirmModal={ this.handleCloseConfirmModal }
                />

            </div>
        );
    }
}
