import { CALL_API } from '../../common/constants/actionTypes';
import ACTION_TYPES from '../constants/actionTypes';
import { apiUrls } from '../constants/apiUrls';

export const loadNotifications = () => ({
    type: CALL_API,
    method: 'GET',
    endpoint: apiUrls.getNotifications,
    types: [
        ACTION_TYPES.NOTIFICATIONS_LOADING_START,
        ACTION_TYPES.NOTIFICATIONS_LOADING_FINISH,
        ACTION_TYPES.NOTIFICATIONS_LOADING_FAIL,
    ],
});

export const markSeenAll = date => ({
    type: CALL_API,
    method: 'POST',
    endpoint: apiUrls.markSeenAll,
    body: { date },
    types: [
        ACTION_TYPES.NOTIFICATIONS_UPDATE_START,
        ACTION_TYPES.NOTIFICATIONS_UPDATE_FINISH,
        ACTION_TYPES.NOTIFICATIONS_UPDATE_FAIL,
    ],
});

export const markReadAll = () => ({
    type: CALL_API,
    method: 'POST',
    endpoint: apiUrls.markReadAll,
    types: [
        ACTION_TYPES.NOTIFICATIONS_UPDATE_START,
        ACTION_TYPES.NOTIFICATIONS_UPDATE_FINISH,
        ACTION_TYPES.NOTIFICATIONS_UPDATE_FAIL,
    ],
});

export const markRead = notificationId => ({
    type: CALL_API,
    method: 'POST',
    endpoint: apiUrls.markRead,
    body: { id: notificationId },
    types: [
        ACTION_TYPES.NOTIFICATIONS_UPDATE_START,
        ACTION_TYPES.NOTIFICATIONS_UPDATE_FINISH,
        ACTION_TYPES.NOTIFICATIONS_UPDATE_FAIL,
    ],
});

export const loadNotificationList = page => ({
    type: CALL_API,
    method: 'GET',
    endpoint: apiUrls.getNotificationList(page),
    types: [
        ACTION_TYPES.NOTIFICATION_LIST_LOADING_START,
        ACTION_TYPES.NOTIFICATION_LIST_LOADING_FINISH,
        ACTION_TYPES.NOTIFICATION_LIST_LOADING_FAIL,
    ],
});
