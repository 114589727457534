import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { dictionary } from '@/common/localization/dictionary';

export class MobileNotificationContainer extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        closeMobileBell: PropTypes.func.isRequired,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]).isRequired,
    };

    static defaultProps = {
        isOpen: false,
    };

    closeBell = () => {
        this.props.closeMobileBell();
    };

    render() {
        const { isOpen } = this.props;
        if (!isOpen) {
            return null;
        }

        return (
            <div className="mobile-notification-container">
                <div className="mobile-arrow-back">
                    <div onClick={ this.closeBell } className="arrow-icon">
                        <i className="fa fa-angle-left" />
                    </div>
                    <div className="arrow-text">
                        { dictionary.notifications }
                    </div>
                </div>
                { this.props.children }
            </div>
        );
    }
}
