import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { locale } from '@/common/localization/dictionary';
import classes from './LocaleDropdown.module.scss';

export const LocaleDropdown = ({ handleSelectLocale }) => {
    const locales = [
        { value: 'ru', label: 'русский', icon: '/media/foreign_employees/languages/russian.png' },
        { value: 'tg', label: 'точики', icon: '/media/foreign_employees/languages/tajik.png' },
        { value: 'uz', label: 'o\'zbekja', icon: '/media/foreign_employees/languages/uzbek.png' },
    ];

    const [isOpen, setIsOpen] = useState(false);
    const ref = useRef(null);
    const [currentLocale, setCurrentLocale] = useState(
        locales.find(item => item.value === locale),
    );

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const onItemClick = (item) => {
        setCurrentLocale(item);
        handleSelectLocale({ value: item.value });
        setIsOpen(false);
    };

    return (
        <div className={ classes.wrapper } ref={ ref }>
            <div onClick={ () => setIsOpen(!isOpen) } className={ isOpen ? classes.labelWrapperOpened : classes.labelWrapper }>
                <img src={ currentLocale.icon } alt="" className={ classes.labelImg } />
            </div>

            {isOpen && (
                <div className={ classes.menu }>
                    {locales.map(item => (
                        <div
                            className={ classes.item }
                            key={ item.slug }
                            onClick={ () => onItemClick(item) }
                        >
                            <img src={ item.icon } className={ classes.menuImg } alt="" />
                            {item.label}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

LocaleDropdown.propTypes = {
    handleSelectLocale: PropTypes.func.isRequired,
};
