import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ResultItemWrapper } from './ResultItemWrapper';
import { ResultItemText } from './ResultItemText';
import { ResultItemPic } from './ResultItemPic';
import { CallForm } from '../../../sys_call/CallForm';

const callFormPopoverProps = {
    style: {
        zIndex: 2100,
    },
    className: 'searchFormContent',
    useLayerForClickAway: false,
};

export class UserResultItem extends Component {
    static propTypes = {
        user_id: PropTypes.number.isRequired,
        phone_number: PropTypes.string,
        canCall: PropTypes.bool,
    };

    static defaultProps = {
        phone_number: undefined,
        canCall: false,
    };

    render() {
        return (
            <ResultItemWrapper { ...this.props }>
                <ResultItemPic { ...this.props } />
                <ResultItemText { ...this.props } />
                {
                    this.props.canCall && this.props.phone_number &&
                        <div style={ { margin: 'auto 15px' } }>
                            <CallForm
                                user_pk={ Number(this.props.user_id) }
                                phone_type="mobile"
                                popoverProps={ callFormPopoverProps }
                            />
                        </div>
                }
            </ResultItemWrapper>
        );
    }
}
