import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog } from 'intdev-ui';

const style = {
    confirmCloseStyle: {
        width: '420px',
    },
};


export class ConfirmCloseDialog extends React.Component {
    static propTypes = {
        confirmModalIsOpen: PropTypes.bool.isRequired,
        closeModal: PropTypes.func.isRequired,
        onCloseConfirmModal: PropTypes.func.isRequired,
    };

    handleConfirm = () => {
        this.props.onCloseConfirmModal();
        this.props.closeModal();
    };

    render() {
        const confirmCloseActions = [
            <Button
                label="Подтвердить"
                primary
                onClick={ this.handleConfirm }
            />,
            <Button label="Отмена" onClick={ this.props.onCloseConfirmModal } />,
        ];

        return (
            <Dialog
                actions={ confirmCloseActions }
                contentStyle={ style.confirmCloseStyle }
                title="Подтвердите действие"
                open={ this.props.confirmModalIsOpen }
                onRequestClose={ this.props.onCloseConfirmModal }
            >
                Вы уверены, что хотите закрыть окно? Несохраненные данные будут потеряны
            </Dialog>
        );
    }
}
