import React, { useState } from 'react';
import { A } from 'intdev-ui';
import BalanceIcon from '@/base_page/assets/BalanceIcon.svg';
import ThanksIcon from '@/base_page/assets/ThanksIcon.svg';
import { dictionary } from '@/common/localization/dictionary';
import PropTypes from 'prop-types';
import classes from './UserInfo.module.scss';

export const UserInfo = ({ handleClose }) => {
    const {
        avatar, fullname, url, coins, thanks, appointment,
    } = window.USER_PROFILE;

    const [shownAvatar, setShownAvatar] = useState(`${avatar}&nodefault=1`);

    const avatarStub = '/static/without_foto_medium.jpg';

    const handleAvatarFallback = () => {
        setShownAvatar(avatarStub);
    };
    const normalizeString = string => string[0].toLowerCase() + string.slice(1);

    return (
        <div className={ classes.container }>
            <A href={ url } onClick={ handleClose }>
                <img src={ `${shownAvatar}` } alt={ fullname } className={ classes.avatar } onError={ handleAvatarFallback } />
            </A>
            <A href={ url } className={ classes.link } onClick={ handleClose }><div>{fullname}</div></A>
            <div className={ classes.appointment }>{appointment}</div>
            <div className={ classes.counters }>
                <div className={ classes.countersBalanceContainer }>
                    {normalizeString(dictionary.balance)}
                    <p className={ classes.countersBalance }>
                        {coins}
                    </p>
                    <img src={ BalanceIcon } alt="баланс" />
                </div>
                <div className={ classes.countersThanksContainer }>
                    {normalizeString(dictionary.thanks(thanks))}
                    <p className={ classes.countersThanks }>
                        {thanks}
                    </p>
                    <img src={ ThanksIcon } alt="благодарности" />
                </div>
            </div>
        </div>
    );
};

UserInfo.propTypes = {
    handleClose: PropTypes.func,
};

UserInfo.defaultProps = {
    handleClose: null,
};
