import React, { useEffect, useRef, useState } from 'react';
import { MenuButton } from '@/base_page/components/AdditionalMenu/MenuButton';
import { A } from 'intdev-ui';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { apiUrls } from '@/base_page/constants/apiUrls';
import { isEmpty } from 'lodash-es';
import classes from './AdditionalMenu.module.scss';

export const AdditionalMenu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const ref = useRef(null);
    const [menu, setMenu] = useState({ menu: [], perms: [] });

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    useEffect(() => {
        callApi(apiUrls.getAdditionalMenu).then(res => setMenu(res));
    }, []);

    const filterMenuByPerms = (menuList) => {
        const filteredItemsByPerm = menuList.map((menuItem) => {
            const filteredSubmenuItems = menuItem.submenu_items.filter(item => ((!item.perm || menu.perms.includes(item.perm))
                && item.feature !== false));
            return {
                ...menuItem,
                items: filteredSubmenuItems,
            };
        });

        const filteredMenu = filteredItemsByPerm.filter(category => !isEmpty(category.items));

        return filteredMenu;
    };

    const filteredMenu = filterMenuByPerms(menu.menu);

    return (
        <div
            className={ classes.container }
            ref={ ref }
        >
            {!isEmpty(filteredMenu) && (
                <div
                    onMouseEnter={ () => setShowTooltip(true) }
                    onMouseLeave={ () => setShowTooltip(false) }
                    style={ { display: 'flex' } }
                >
                    <MenuButton isOpen={ isOpen } setIsOpen={ setIsOpen } />
                </div>
            )}
            {showTooltip && !isOpen && (
                <div className={ classes.tooltip }>Вспомогательное меню</div>
            )}
            {isOpen && (
                <div className={ classes.menu }>
                    {filteredMenu.map(item => (
                        <div key={ item.id }>
                            <p className={ classes.groupTitle }>{item.name}</p>
                            <div className={ classes.groupContainer }>
                                {item.items.map(subitem => (
                                    <A
                                        href={ subitem.url }
                                        key={ subitem.id }
                                        target={ subitem.newtab ? '_blank' : '_self' }
                                        className={ classes.groupLink }
                                    >
                                        { subitem.name }
                                    </A>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
