import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SearchForm } from 'intdev-ui';
import { searchContexts } from '../constants/constants';
import { SearchResultItem } from './SearchResultItem';
import withDefaultParams from '../../common/components/DefaultParams/components/withDefaultParams';


/**
 * Доступные контексты поиска задаются через DbOption "selectable_search_contexts".
 * Значение DbOption - поля "value" доступных searchContexts через запятую: "q,users.userprofile,ideas_tracker.idea".
 * По умолчанию доступны все контексты.
 */

class HeaderSearchComponent extends Component {
    static propTypes = {
        onItemSelected: PropTypes.func,
        projectConstants: PropTypes.shape({
            isFetching: PropTypes.bool,
            db_options: PropTypes.shape({
                selectable_search_contexts: PropTypes.string,
            }),
        }),
    };

    static defaultProps = {
        projectConstants: {},
        onItemSelected: null,
    };

    state = {
        allowedContexts: [],
        context: null,
        options: [],
    };

    componentDidUpdate(prevProps) {
        if (prevProps.projectConstants.isFetching && !this.props.projectConstants.isFetching) {
            const allowedContexts = this.getSearchContexts();
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
                allowedContexts,
                context: allowedContexts[0],
            });
        }
    }

    getSearchContexts() {
        const dbOptions = this.props.projectConstants.db_options;
        const selectableSearchContexts = dbOptions && dbOptions.selectable_search_contexts;
        if (!selectableSearchContexts) {
            return searchContexts;
        }
        const allowedContexts = (
            selectableSearchContexts
        ).split(',').map(c => c.trim()).filter(c => c);
        if (!allowedContexts.length) {
            return searchContexts;
        }
        return searchContexts.filter(context => allowedContexts.includes(context.value));
    }

    handleContextChange = (newContext) => {
        const context = newContext || this.state.allowedContexts[0];
        this.setState({ context });
    };

    render() {
        return (<div className="header-search-form-container">
            <SearchForm
                action="/go/"
                placeholder="Поиск по интранету"
                onContextChange={ this.handleContextChange }
                onItemSelected={ this.props.onItemSelected }
                context={ this.state.context }
                contexts={ this.state.allowedContexts }
                minLength={ 2 }
                resultItemComponent={ SearchResultItem }
                loadOptions={ input => new Promise((resolve) => {
                    const context = this.state.context ? this.state.context.value : 'q';
                    const payload = {
                        searchTerm: input,
                        searchContext: context,
                    };

                    $.ajax({
                        dataType: 'json',
                        url: '/go/suggest/',
                        scriptCharset: 'utf-8',
                        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
                        data: payload,
                        success(data) {
                            resolve({ options: data });
                        },
                    });
                }) }
            />
        </div>);
    }
}


export const HeaderSearch = withDefaultParams(
    HeaderSearchComponent,
    { needConstants: { db_options: ['selectable_search_contexts'] } },
);
