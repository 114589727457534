import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Select, TextField } from 'intdev-ui';

import { callApi } from '../../../../common/middlewares/apiMiddleware';
import { showErrorNotification } from '../../../../common/helpers/showNotification';
import { JSON_FORMAT } from '../../../../common/constants/ajaxBodyTypes';
import { BeautifulRedactorField } from '../../../../common/components/BeautifulRedactorField';
import { selectOption } from '../../constants';


import { styles } from './styles';
import { getValidationErrors, shouldCreateNewLabelOption } from '../../utils';


export class EmailOptionForm extends React.Component {
    static propTypes = {
        optionId: PropTypes.number,
        targetUser: selectOption,
        buttonId: PropTypes.number,
        title: PropTypes.string,
        emails: PropTypes.arrayOf(PropTypes.shape()),
        description: PropTypes.string,
        allow_fill_subject: PropTypes.bool,
        subject: PropTypes.string,
        send_to_author: PropTypes.bool,
        submitUrl: PropTypes.string.isRequired,
        updateOptionData: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        optionId: null,
        buttonId: null,
        targetUser: null,
        title: '',
        emails: '',
        description: '',
        allow_fill_subject: false,
        subject: '',
        send_to_author: false,
    };

    state = {
        submittingOption: false,
        title: this.props.title,
        emails: this.props.emails,
        description: this.props.description,
        allow_fill_subject: this.props.allow_fill_subject,
        subject: this.props.subject,
        send_to_author: this.props.send_to_author,
        errors: {
            title: '',
            emails: '',
            description: '',
        },
    };

    getErrorType = (fieldName) => {
        const errors = this.state.errors;
        return errors[fieldName] ? 'error' : null;
    };

    serializeForm = () => ({
        title: this.state.title,
        emails: this.state.emails,
        description: this.state.description,
        send_to_author: this.state.send_to_author,
        allow_fill_subject: this.state.allow_fill_subject,
        subject: this.state.subject,
        user: this.props.targetUser && this.props.targetUser.value,
        button: this.props.buttonId,
    });

    handleSubmit = () => {
        this.setState({ submittingOption: true });
        callApi(
            this.props.submitUrl,
            this.props.optionId ? 'put' : 'post',
            this.serializeForm(),
            JSON_FORMAT,
        ).then((createdInstance) => {
            this.props.updateOptionData({
                ...createdInstance,
                isEmailOption: true,
            });
            this.setState({ submittingOption: false });
        }).catch((resp) => {
            if (resp.status > 400) {
                showErrorNotification('Не удалось сохранить настройку');
            }
            this.setState({ submittingOption: false });

            const formFieldNames = Object.keys(this.state.errors);
            return this.setState({
                errors: getValidationErrors(resp, formFieldNames),
                submittingOption: false,
            });
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({ [target.name]: target.value });
    };

    handleDescriptionChange = (value) => {
        this.setState({ description: value });
    };

    handleFieldChange = fieldName => (value) => {
        this.setState({ [fieldName]: value });
    };

    handleToggleSendToAuthor = () => {
        this.setState(prevState => ({ send_to_author: !prevState.send_to_author }));
    };

    handleToggleAllowSubject = () => {
        this.setState(prevState => (
            prevState.allow_fill_subject ?
                { allow_fill_subject: !prevState.allow_fill_subject } :
                { allow_fill_subject: !prevState.allow_fill_subject, subject: '' }
        ));
    };

    render() {
        return (
            <div>
                <div style={ styles.formRow }>
                    <label style={ styles.label }>Название</label>
                    <TextField
                        value={ this.state.title }
                        name="title"
                        onChange={ this.handleInputChange }
                        validationState={ this.getErrorType('title') }
                        validationText={ this.state.errors.title }
                        fullWidth
                        disabled={ this.state.submittingOption }
                    />
                </div>

                <div style={ styles.formRow }>
                    <label style={ styles.label }>Email получателей</label>

                    <Select.Creatable
                        multi
                        placeholder="Введите адреса получателей"
                        fullWidth
                        name="emails"
                        value={ this.state.emails }
                        cache={ false }
                        minLength={ 2 }
                        onChange={ this.handleFieldChange('emails') }
                        noResultsText="Нет результатов"
                        searchPromptText="Нет результатов"
                        shouldKeyDownEventCreateNewOption={ shouldCreateNewLabelOption }
                        validationState={ this.getErrorType('emails') }
                        validationText={ this.state.errors.email }
                        disabled={ this.state.submittingOption }
                    />
                </div>

                <div style={ styles.formRow } >
                    <label style={ styles.checkbox }>
                        <Checkbox
                            onChange={ this.handleToggleSendToAuthor }
                            name="send_to_author"
                            checked={ this.state.send_to_author }
                            disabled={ this.state.submittingOption }
                        />
                        &nbsp;
                        Отправлять копию письма автору
                    </label>
                </div>

                <div style={ styles.formRow } >
                    <label style={ styles.checkbox }>
                        <Checkbox
                            onChange={ this.handleToggleAllowSubject }
                            name="allow_fill_subject"
                            checked={ this.state.allow_fill_subject }
                            disabled={ this.state.submittingOption }
                        />
                        &nbsp;
                        Тему письма заполняет автор
                    </label>
                </div>

                <div style={ styles.formRow }>
                    <label style={ styles.label }>Тема письма</label>
                    <TextField
                        value={ this.state.subject }
                        name="subject"
                        onChange={ this.handleInputChange }
                        validationState={ this.getErrorType('subject') }
                        validationText={ this.state.errors.subject }
                        fullWidth
                        disabled={
                            this.state.submittingOption || this.state.allow_fill_subject
                        }
                    />
                </div>

                <div style={ styles.formRow }>
                    <label style={ styles.label }>Описание (показывается в окне создания письма)</label>
                    <BeautifulRedactorField
                        name="description"
                        disabled={ this.state.submittingOption }
                        value={ this.state.description }
                        onChange={ this.handleDescriptionChange }
                        formatting
                        mentions
                        imagemanager
                        fullWidth
                        height={ 50 }
                        validationState={ this.getErrorType('description') }
                        validationText={ this.state.errors.description }
                    />
                </div>

                <div style={ styles.footer }>
                    <Button
                        label="Сохранить"
                        onClick={ this.handleSubmit }
                        primary
                    />
                    <Button
                        label="Отмена"
                        style={ { marginLeft: 10 } }
                        onClick={ this.props.onCancel }
                    />
                </div>
            </div>
        );
    }
}
