import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { PropTypeOrNull } from '@/common/constants/propTypes';
import ArrowIcon from '@/base_page/components/BaseMenu/assets/Arrow.svg';
import classes from './ExpandableItem.module.scss';

export const ExpandableItem = ({
    item, isExpanded, handleClick, handleCloseMenu,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const submenuRef = useRef(null);

    useEffect(() => {
        setIsOpen(isExpanded);
    }, [isExpanded]);

    const onClick = () => handleClick(item.name);

    return (
        <div className={ isOpen ? classes.itemExpanded : classes.item }>
            <div className={ isOpen ? classes.containerExpanded : classes.container } onClick={ onClick }>
                <div className={ `${isOpen ? classes.wrapperExpanded : classes.wrapper} ` }>
                    {item.image && <img src={ item.image } className={ classes.icon } alt="" />}
                    <div className={ classes.title }>
                        {item.name}
                    </div>
                    <img src={ ArrowIcon } alt="" className={ `${isOpen ? classes.arrowUp : classes.arrow}` } />
                </div>
            </div>
            <div className={ classes.submenu } ref={ submenuRef } style={ { height: isOpen ? submenuRef.current.scrollHeight : 0 } }>
                { item.submenu_items.map(subitem => (
                    <a
                        href={ subitem.url }
                        key={ subitem.id }
                        target={ subitem.newtab ? '_blank' : '_self' }
                        onClick={ handleCloseMenu }
                        style={ { textDecoration: 'none', cursor: 'inherit' } }
                    >
                        <div className={ classes.submenuItem }>
                            { subitem.name }
                        </div>
                    </a>
                )) }
            </div>
        </div>
    );
};

ExpandableItem.propTypes = {
    item: PropTypes.shape({
        name: PropTypes.string,
        image: PropTypes.string,
        custom_contents: PropTypes.string,
        newtab: PropTypes.bool,
        submenu_items: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
            url: PropTypes.string,
            perm: PropTypeOrNull(PropTypes.string),
        })),
    }).isRequired,
    isExpanded: PropTypes.bool.isRequired,
    handleClick: PropTypes.func.isRequired,
    handleCloseMenu: PropTypes.func,
};

ExpandableItem.defaultProps = {
    handleCloseMenu: null,
};
