import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';
import { linkStyles } from '../../../common/components/ALink';

const styles = {
    itemWrapper: {
        minHeight: 32,
        borderBottom: '1px solid #e9e9e9',
        padding: '0 12px 4px',
        whitespace: 'nowrap',
        display: 'flex',
        fontSize: 15,
        alignItems: 'center',
        marginBottom: 13,
    },
    itemWrapperHovered: {
        background: '#f0f0f0',
    },
};

export class ResultItemWrapper extends Component {
    static propTypes = {
        isHovered: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func,
        data: PropTypes.shape({
            href: PropTypes.string.isRequired,
        }).isRequired,
        children: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.node),
            PropTypes.node,
        ]).isRequired,
    };

    static defaultProps = {
        isHovered: false,
        onMouseEnter: () => {},
        onMouseLeave: () => {},
    };

    render() {
        const {
            isHovered,
            onMouseEnter,
            onMouseLeave,
            data: { href },
            children,
        } = this.props;

        const wrapperStyle = isHovered ? { ...styles.itemWrapper, ...styles.itemWrapperHovered } : styles.itemWrapper;

        return (
            <A
                href={ href }
                onMouseEnter={ onMouseEnter }
                onMouseLeave={ onMouseLeave }
                style={ { color: '#333', textDecoration: 'none' } }
                hoveredStyle={ linkStyles.noUnderline }
            >
                <div style={ wrapperStyle }>
                    { children }
                </div>
            </A>
        );
    }
}
