import React, { Component } from 'react';
import { ResultItemWrapper } from './ResultItemWrapper';
import { ResultItemText } from './ResultItemText';
import { ResultItemPic } from './ResultItemPic';

export const DefaultResultItem = props => {
    return (
        <ResultItemWrapper { ...props }>
            <ResultItemPic { ...props } />
            <ResultItemText { ...props } />
        </ResultItemWrapper>
    );
};
