import React, { useEffect, useState } from 'react';
import { A, Drawer } from 'intdev-ui';
import { MenuToggleButton } from '@/base_page/common/MenuToggleButton';
import { UserInfo } from '@/base_page/common/UserInfo';
import CloseButtonIcon from '@/base_page/assets/CloseButton.svg';
import { BaseMenu } from '@/base_page/components/BaseMenu';
import { useDispatch, useSelector } from 'react-redux';
import { headerMenuSelector } from '@/base_page/selectors';
import { isEmpty } from 'lodash-es';
import { getMenu } from '@/base_page/actions/actions';
import { matchPath } from 'react-router';
import { useLocation } from 'react-router-dom';
import { OwnGroupSelect } from '@/common/components/OwnGroupSelect';
import { apiUrls } from '@/base_page/constants/apiUrls';
import { filterMenuByPerms } from '@/base_page/helpers';
import classes from './MenuDrawer.module.scss';

export const MenuDrawer = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const menu = useSelector(headerMenuSelector);
    const [showTooltip, setShowTooltip] = useState(false);
    const location = useLocation();

    const handleOpenMenu = () => setIsOpen(true);
    const handleCloseMenu = () => setIsOpen(false);

    const EXCLUDE_PAGES = ['/badges/', '/skills/', '/timeline/micropost/', '/notification_center/', '/service_report/', '/quiz/builder/'];

    const shouldDisableDrawer = EXCLUDE_PAGES.some(path => location.pathname.startsWith(path))
        || matchPath(location.pathname, '/').isExact;

    useEffect(() => {
        if (isEmpty(menu.menu) && !shouldDisableDrawer) dispatch(getMenu());
    }, [shouldDisableDrawer]);

    const filteredMenu = filterMenuByPerms(menu);

    return (
        <div
            style={ { display: 'flex', alignItems: 'center', position: 'relative' } }

        >
            <div
                onMouseEnter={ () => setShowTooltip(true) }
                onMouseLeave={ () => setShowTooltip(false) }
                style={ { display: 'flex' } }
            >
                <MenuToggleButton disabled={ shouldDisableDrawer } onClick={ handleOpenMenu } />
            </div>
            {!shouldDisableDrawer && showTooltip && (
                <div className={ classes.tooltip }>Меню</div>
            )}
            <Drawer
                open={ isOpen }
                width={ 440 }
                docked={ false }
                onRequestChange={ () => setIsOpen(prev => !prev) }
            >
                <div className={ classes.headerWrapper }>
                    <div className={ classes.headerTitle }>Меню</div>
                    <button className={ classes.headerBtn } type="button" onClick={ handleCloseMenu }>
                        <img src={ CloseButtonIcon } alt="закрыть" />
                    </button>
                </div>
                <div className={ classes.drawerContent }>
                    <div className={ classes.drawerContentInfo }>
                        <UserInfo handleClose={ () => setIsOpen(false) } />
                    </div>
                    <div className={ classes.drawerContentMenu }>
                        <BaseMenu navigationMenu={ filteredMenu } handleCloseMenu={ handleCloseMenu } />
                        <div className={ classes.admin }>
                            <OwnGroupSelect />
                            {
                                menu?.perms?.includes('navigation.change_menuitem') ? (
                                    <div className={ classes.adminLinkContainer }>
                                        <A href={ apiUrls.menuAdmin } className={ classes.adminLink }>Изменить меню</A>
                                    </div>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};
