import $ from 'jquery';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CallButton } from '../sys_call/CallButton';
import MrgIcon from '../common/components/MrgIcon';
import './CallForm.css';

export class CallForm extends Component {
    static propTypes = {
        user_pk: PropTypes.number,
        phone_number: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        phone_type: PropTypes.string.isRequired,
        label: PropTypes.node,
        errorLabel: PropTypes.node,
        inProgressLabel: PropTypes.node,
        popoverProps: PropTypes.shape(),
    };

    static defaultProps = {
        user_pk: null,
        phone_number: null,
        label: <MrgIcon className="icon_actions--call" />,
        errorLabel: <MrgIcon className="icon_status--error_triangle" />,
        inProgressLabel: <MrgIcon className="icon_status--hourglass_start hourglass_animated" />,
        popoverProps: {},
    };

    state = {
        callInProgress: false,
        devices: [],
        isOpenPopover: false,
        error: false,
    };

    handleRequestClose = () => {
        this.setState({
            isOpenPopover: false,
        });
    };

    openPopover = (data) => {
        this.setState({
            devices: data.devices,
            isOpenPopover: true,
        });
    };

    makeCall = () => {
        this.setState({
            isOpenPopover: false,
            callInProgress: true,
        });
        setTimeout(() => {
            this.setState({ callInProgress: false });
        }, 4000);
    };

    handleError = () => {
        this.setState({
            isOpenPopover: false,
            error: true,
        });
        setTimeout(() => {
            this.setState({ error: false });
        }, 4000);
    };

    requestCall = (deviceModel) => {
        // eslint-disable-next-line camelcase
        const { user_pk, phone_type, phone_number } = this.props;
        const data = {
            user_pk,
            phone_type,
            phone_number,

        };
        if (deviceModel) {
            data.device_name = deviceModel;
        }
        $.ajax({
            url: '/users/make_call/',
            type: 'POST',
            data,
            dataType: 'json',
            success: (responseData) => {
                if (responseData.msg === 'multiple') {
                    this.openPopover(responseData);
                } else if (responseData.msg === 'ok') {
                    this.makeCall();
                } else if (responseData.msg === 'error') {
                    this.handleError();
                }
            },
        });
    };

    render() {
        let label;
        let currentStatus;
        if (this.state.error) {
            label = this.props.errorLabel;
            currentStatus = 'error';
        } else if (this.state.callInProgress) {
            label = this.props.inProgressLabel;
            currentStatus = 'inProgress';
        } else if (!this.state.callInProgress) {
            label = this.props.label;
            currentStatus = 'ready';
        }
        return (
            <CallButton
                status={ currentStatus }
                devices={ this.state.devices }
                label={ label }
                onRequestClose={ this.handleRequestClose }
                isOpenPopover={ this.state.isOpenPopover }
                requestCall={ this.requestCall }
                popoverProps={ this.props.popoverProps }
            />
        );
    }
}
