import React, { Component } from 'react';
import PropTypes from 'prop-types';

export class ResultItemPic extends Component {
    static propTypes = {
        text: PropTypes.string.isRequired,
        pic: PropTypes.string,
    };

    static defaultProps = {
        pic: undefined,
    };

    render() {
        const {
            text,
            pic,
        } = this.props;

        if (!pic) return null;

        return (
            <div style={ { margin: 'auto 0' } }>
                <img
                    src={ `${pic}` }
                    alt={ text }
                    style={ {
                        borderRadius: '50%', width: 42, height: 42, marginRight: 12,
                    } }
                />
            </div>
        );
    }
}
