const getUrlPrefix = isFranchiseeStuff => (isFranchiseeStuff ? 'franchisee' : 'accumulator');

export const apiUrls = {
    // настраиваемые блоки
    fetchMainBanner: pageType => `/${pageType}/api/blocks/banner/`,
    fetchGreeting: pageType => `/${pageType}/api/blocks/greeting/`,
    fetchKnowledgeBase: pageType => `/${pageType}/api/blocks/knowledge-base/`,
    fetchNewIdeaLink: pageType => `/${pageType}/api/blocks/new-idea/`,
    fetchGreetingLinkButton: pageType => `/${pageType}/api/blocks/greeting-button/`,
    fetchTeam: pageType => `/${pageType}/api/blocks/my-team/`,
    fetchCreeds: pageType => `/${pageType}/api/blocks/creeds/`,
    fetchInstructions: pageType => `/${pageType}/api/blocks/instructions/`,
    fetchTopAuthors: pageType => `/${pageType}/api/blocks/top-authors/`,
    fetchRulesLinkButton: pageType => `/${pageType}/api/blocks/rules-button/`,

    // боковые блоки
    fetchIdeasStatuses: pageType => `/${pageType}/api/rating/status_counters/`,
    fetchIdeasRanking: pageType => `/${pageType}/api/rating/vote_rating/`,

    // crud идеи
    fetchEditForm: pageType => (id = null) => (id
        ? `/${pageType}/api/idea/${id}/form/`
        : `/${pageType}/api/idea/form/`),
    createIdea: pageType => `/${pageType}/api/idea/`,
    updateIdea: pageType => id => `/${pageType}/api/idea/${id}/`,
    deleteIdea: pageType => id => `/${pageType}/api/idea/${id}/`,
    declineIdea: pageType => id => `/${pageType}/api/idea/${id}/decline/`,

    // функционал просмотра идей, фильтров, подписки
    fetchIdea: pageType => ideaId => `/${pageType}/api/idea/${ideaId}/`,
    fetchIdeas: pageType => `/${pageType}/api/idea/`,
    fetchFilters: pageType => `/${pageType}/api/filters/`,
    ideasSubscriptionUrl: pageType => `/${pageType}/api/idea/subscription/`,
    fetchUserSuggestById: pageType => userId => `/${pageType}/api/idea/author/?id=${userId}`,

    // опции для обновления состояния идеи
    getStatusesOptions: pageType => `/${pageType}/api/idea_props/`,
    getResponsibleUserOptions: pageType => `/${pageType}/api/search_responsible/`,

    // чат экспертов
    fetchExpertMessages: (pageType, ideaId) => `/${pageType}/api/idea/${ideaId}/expert_comments/`,
    changeExpertMessage: messageId => `/accumulator/api/expert_comments/${messageId}/`,
    viewExpertMessage: messageId => `/accumulator/api/expert_comments/${messageId}/mark_as_viewed/`,

    // другие urls
    sendFeedback: (pageType, id) => `/${pageType}/api/idea/${id}/feedback/`,
    fetchPieChartData: pageType => `/${pageType}/api/statistics/`,
    fetchGanttChartData: pageType => `/${pageType}/api/chart_gantt/`,
    getExcelReport: pageType => filter => `/${pageType}/api/export/${filter}`,

    // urls для компонентов, которые используются не только на страницах приложения ideas_pek,
    // и поэтому не имеют свойства pageType
    voteForIdea: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/vote/`,
    makeOfficialResponse: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/make_official_response/`,
    loadOfficialResponse: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/get_official_response/`,
    getEditHistory: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/history/`,

    // обновление состояния идеи в рамках ее жизненнного цикла
    changeStatus: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/status/`,
    changeDepartment: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/department/`,
    changeResponsible: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/responsible_change/`,
    changeDate: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/date_change/`,
    changeResources: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/resource/`,
    changeSystems: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/upgrade_systems/`,
    changeTimeBudget: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/time_budget/`,
    changeMoneyBudget: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/money_budget/`,
    changeComplexity: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/complexity/`,
    changeEconomicEffect: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/economic_effect/`,
    changeBacklog: (id, isFranchiseeIdea) => `/${getUrlPrefix(isFranchiseeIdea)}/api/idea/${id}/backlog/`,

    // из внешних приложений
    getUserOptions: filter => `/users/autocomplete/user?q=${filter}`,
    uploadImageUrl: '/uploads/ajax-upload_new/',
    fetchImageUrl: '/uploads/url-upload/',
};

export const urls = {
    ideasHomePage: pageType => `/${pageType}/`,
    ideaAddPage: pageType => `/${pageType}/new/`,
    ideaEditPage: pageType => id => `/${pageType}/edit/${id}/`,
    ideaViewPage: pageType => id => `/${pageType}/${id}/`,
    ideasStatisticsPage: pageType => `/${pageType}/statistics/`,
    userIdeasPage: (userId, isFranchisee) => `/${getUrlPrefix(isFranchisee)}/?author=${userId}`,
};
