import PropTypes from 'prop-types';

export const JIRA_TASK_KEY = 'SHOW_JIRA_TASK_FORM';
export const JIRA_TASK_USER_FORM = 'JIRA_TASK_USER_FORM';


export const selectOption = PropTypes.shape({
    label: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
    ]),
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
});

export const selectOptions = PropTypes.arrayOf(selectOption);
