import ACTION_TYPES from '../constants/actionTypes';


export const notificationUpstream = message => ({
    type: ACTION_TYPES.CENTRIFUGE_MESSAGE_RECEIVED,
    message,
});

export const synchronizeTabs = event => ({
    type: ACTION_TYPES.SYNCHRONIZE_TABS,
    event,
});
