import React from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Select, TextField } from 'intdev-ui';

import { callApi } from '../../../../common/middlewares/apiMiddleware';
import { showErrorNotification } from '../../../../common/helpers/showNotification';
import { JSON_FORMAT } from '../../../../common/constants/ajaxBodyTypes';
import { getComponentOptionsUrl } from '../../../../user_detail/apiUrls';
import { BeautifulRedactorField } from '../../../../common/components/BeautifulRedactorField';
import { styles } from './styles';
import { selectOption, selectOptions } from '../../constants';
import { getValidationErrors, shouldCreateNewLabelOption } from '../../utils';


/**
 * Форма создания/редактирования Настройки создания Jira-тасков (TaskCreationOption).
 */


export class JiraOptionForm extends React.Component {
    static propTypes = {
        optionId: PropTypes.number,
        title: PropTypes.string,
        description: PropTypes.string,
        project: selectOption,
        targetUser: selectOption,
        buttonId: PropTypes.number,
        component: selectOption,
        issueType: selectOption,
        labels: selectOptions,
        addUserAgent: PropTypes.bool,
        projectOptions: selectOptions.isRequired,
        submitUrl: PropTypes.string.isRequired,
        fallbackEmail: PropTypes.string,
        updateOptionData: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
    };

    static defaultProps = {
        optionId: null,
        title: '',
        description: '',
        project: null,
        component: null,
        labels: [],
        addUserAgent: false,
        issueType: null,
        targetUser: null,
        buttonId: null,
        fallbackEmail: '',
        updateMode: false,
    };

    state = {
        submittingOption: false,
        title: this.props.title,
        description: this.props.description,
        project: this.props.project,
        component: this.props.component,
        issue_type: this.props.issueType,
        labels: this.props.labels,
        add_user_agent: this.props.addUserAgent,
        fallback_email: this.props.fallbackEmail,
        issueTypeOptions: null,
        componentOptions: null,
        errors: {
            title: '',
            project: '',
            component: '',
            issue_type: '',
        },
    };

    componentDidMount() {
        if (this.props.optionId) {
            this.updateOptions(this.state.project);
        }
    }

    getErrorType = (fieldName) => {
        const errors = this.state.errors;
        return errors[fieldName] ? 'error' : null;
    };

    serializeForm = () => {
        const form = {
            title: this.state.title,
            description: this.state.description,
            user: this.props.targetUser && this.props.targetUser.value,
            button: this.props.buttonId,
            issue_labels: this.state.labels.map(label => label.value),
            add_user_agent: this.state.add_user_agent,
            fallback_email: this.state.fallback_email,
        };
        for (const field of ['project', 'component', 'issue_type']) {
            if (this.state[field]) {
                form[field] = this.state[field].value;
            }
        }
        return form;
    };

    updateOptions = (value) => {
        callApi(
            getComponentOptionsUrl,
            'get',
            { project_id: value.value },
        ).then((data) => {
            this.setState({
                issueTypeOptions: data.issueTypeOptions,
                componentOptions: data.componentOptions,
            });
        });
    };

    handleSubmit = () => {
        this.setState({ submittingOption: true });
        callApi(
            this.props.submitUrl,
            this.props.optionId ? 'put' : 'post',
            this.serializeForm(),
            JSON_FORMAT,
        ).then((createdInstance) => {
            this.props.updateOptionData(createdInstance);
            this.setState({ submittingOption: false });
        }).catch((resp) => {
            if (resp.status > 400) {
                showErrorNotification('Не удалось сохранить настройку');
            }
            const formFieldNames = Object.keys(this.state.errors);
            return this.setState({
                errors: getValidationErrors(resp, formFieldNames),
                submittingOption: false,
            });
        });
    };

    handleInputChange = (event) => {
        const target = event.target;
        this.setState({
            [target.name]: target.value,
        });
    };

    handleDescriptionChange = (value) => {
        this.setState({ description: value });
    };

    handleToggleAddAgent = () => {
        this.setState(prevState => ({ add_user_agent: !prevState.add_user_agent }));
    };

    handleProjectChange = (value) => {
        this.updateOptions(value);
        this.setState({
            project: value,
            component: null,
            issue_type: null,
        });
    };

    handleFieldChange = fieldName => (value) => {
        this.setState({ [fieldName]: value });
    };

    render() {
        return (
            <div>
                <div style={ styles.formRow }>
                    <label style={ styles.label }>Название</label>
                    <TextField
                        value={ this.state.title }
                        name="title"
                        onChange={ this.handleInputChange }
                        validationState={ this.getErrorType('title') }
                        validationText={ this.state.errors.title }
                        fullWidth
                        disabled={ this.state.submittingOption }
                    />
                </div>

                <div style={ styles.formRow }>
                    <label style={ styles.label }>Описание (показывается в окне создания задачи)</label>
                    <BeautifulRedactorField
                        name="description"
                        disabled={ this.state.submittingOption }
                        value={ this.state.description }
                        onChange={ this.handleDescriptionChange }
                        formatting
                        mentions
                        imagemanager
                        fullWidth
                        height={ 50 }
                        validationState={ this.getErrorType('description') }
                        validationText={ this.state.errors.description }
                    />
                </div>

                <div style={ styles.formRowMultipleInputs }>
                    <div style={ styles.projectInput }>
                        <label style={ styles.label }>Проект</label>
                        <Select
                            placeholder="Выберите проект..."
                            fullWidth
                            name="project"
                            clearable={ false }
                            value={ this.state.project }
                            options={ this.props.projectOptions }
                            onChange={ this.handleProjectChange }
                            noResultsText="Нет результатов"
                            validationState={ this.getErrorType('project') }
                            validationText={ this.state.errors.project }
                            disabled={ this.state.submittingOption }
                        />
                    </div>

                    <div style={ styles.componentInput }>
                        <label style={ styles.label }>Компонент</label>
                        <Select
                            placeholder="Выберите компонент..."
                            fullWidth
                            name="component"
                            value={ this.state.component }
                            options={ this.state.componentOptions }
                            onChange={ this.handleFieldChange('component') }
                            noResultsText="Нет результатов"
                            validationState={ this.getErrorType('component') }
                            validationText={ this.state.errors.component }
                            disabled={ this.state.submittingOption }
                        />
                    </div>
                </div>

                <div style={ styles.formRowMultipleInputs }>
                    <div style={ styles.issueTypeInput }>
                        <label style={ styles.label }>Тип задачи</label>
                        <Select
                            placeholder="Выберите тип задачи..."
                            fullWidth
                            name="issue_type"
                            value={ this.state.issue_type }
                            options={ this.state.issueTypeOptions }
                            onChange={ this.handleFieldChange('issue_type') }
                            noResultsText="Нет результатов"
                            validationState={ this.getErrorType('issue_type') }
                            validationText={ this.state.errors.issue_type }
                            disabled={ this.state.submittingOption }
                        />
                    </div>
                    <div style={ styles.labelInput }>
                        <label style={ styles.label }>Метки</label>
                        <Select.Creatable
                            multi
                            placeholder="Введите метки..."
                            fullWidth
                            name="labels"
                            value={ this.state.labels }
                            cache={ false }
                            minLength={ 2 }
                            onChange={ this.handleFieldChange('labels') }
                            noResultsText="Нет результатов"
                            searchPromptText="Нет результатов"
                            shouldKeyDownEventCreateNewOption={ shouldCreateNewLabelOption }
                            disabled={ this.state.submittingOption }
                        />
                    </div>
                </div>

                <div style={ styles.formRow } >
                    <label style={ styles.checkbox }>
                        <Checkbox
                            onChange={ this.handleToggleAddAgent }
                            name="add_user_agent"
                            checked={ this.state.add_user_agent }
                            disabled={ this.state.submittingOption }
                        />
                        &nbsp;
                    Добавлять в текст задачи URL и данные о браузере
                    </label>
                </div>
                <div style={ styles.formRow }>
                    <label style={ styles.label }>Если не удается создать таск, отправлять на email:</label>
                    <TextField
                        value={ this.state.fallback_email }
                        name="fallback_email"
                        onChange={ this.handleInputChange }
                        validationState={ this.getErrorType('fallback_email') }
                        validationText={ this.state.fallback_email.title }
                        fullWidth
                        disabled={ this.state.submittingOption }
                    />
                </div>

                <div style={ styles.footer }>
                    <Button
                        label="Сохранить"
                        onClick={ this.handleSubmit }
                        primary
                    />
                    <Button
                        label="Отмена"
                        style={ { marginLeft: 10 } }
                        onClick={ this.props.onCancel }
                    />
                </div>
            </div>
        );
    }
}
