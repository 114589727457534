import React, {
    useCallback, useEffect, useRef, useState,
} from 'react';
import { throttle } from 'lodash-es';
import { Header, NotificationSystem } from 'intdev-ui';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { isMobile } from '@/common/mediaQueries';
import { MobileNotificationContainer } from '@/base_page/components/MobileComponents/MobileNotificationContainer';
import { MenuComponent } from '@/base_page/components/MobileComponents/MenuComponent';
import { changeLocale as changeLocaleUrl } from '@/base_page/constants/constants';
import { callApi } from '@/common/middlewares/apiMiddleware';
import { showErrorNotification } from '@/common/helpers/showNotification';
import {
    getActualTheme, hideHeader, showHeader,
} from '@/base_page/actions/actions';
import { notificationUpstream, synchronizeTabs } from '@/notifications/actions/upstream';
import { loadNotifications } from '@/notifications/actions/notifications';
import { NotificationsContainer } from '@/notifications/components/NotificationsContainer';
import { NOTIFICATION_UPSTREAM_TYPES } from '@/notifications/constants';
import { headerThemeNamePrivate } from '@/styles/styleVars';
import { goOnSpaUrl } from '@/spa/utils/atagClickHelper';
import { headerMenuSelector, weHeaderSelector } from '@/base_page/selectors';
import { HeaderLogo } from '@/base_page/components/HeaderLogo';
import getCentrifugeConnection from '@/centrifuge';
import AuthError from '@/base_page/components/AuthError';
import { HeaderSearch } from '@/base_page/components/Search';

import './WeHeader.css';
import { AdditionalMenu } from '@/base_page/components/AdditionalMenu';
import { MenuDrawer } from '@/base_page/components/MenuDrawer';
import { MenuToggleButton } from '@/base_page/common/MenuToggleButton';
import { BellIcon } from '@/services_common/components/BellIcon';

/*
    Eсли url начинается со строки в этом списке, то не показываем шапку.
    Работает только с не спа приложениями, потому что при спа шапка не перерендеривается.
 */
const WITHOUT_HEADER = ['/confroom-strict/', '/contests/zls_map/'];

const defaultOnToPage = (url) => {
    const goneOnSpaUrl = goOnSpaUrl(url);
    if (!goneOnSpaUrl) {
        window.location.href = url;
    }
};

export const WeHeader = () => {
    const dispatch = useDispatch();
    const {
        notifications, newNotificationsCount, headerIsShown, headerImageUrl,
    } = useSelector(weHeaderSelector);
    const { menu } = useSelector(headerMenuSelector);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [mobileBellIsOpen, setMobileBellIsOpen] = useState(false);
    const [centrifugeSubscription, setCentrifugeSubscription] = useState(null);

    const lastScrollDown = useRef(0);
    const lastScrollUp = useRef(0);
    const lastScroll = useRef(0);
    const hasDisplay = useRef(true);
    const notificationSystem = useRef(null);
    const isFirstRender = useRef(true);

    const throttledScrollListener = throttle(() => {
        const pageY = window.pageYOffset;
        if (pageY > 0 && pageY >= lastScroll.current) { // scrolling down
            lastScroll.current = pageY;
            lastScrollDown.current = pageY;
            if (pageY > 120 && lastScroll.current >= lastScrollUp.current + 10 && hasDisplay.current) {
                hasDisplay.current = false;
                dispatch(hideHeader());
            }
        } else { // scrolling up
            lastScroll.current = pageY;
            lastScrollUp.current = pageY;
            if ((lastScroll.current <= lastScrollDown.current - 10 || pageY < 120) && !hasDisplay.current) {
                hasDisplay.current = true;
                dispatch(showHeader());
            }
        }
    }, 500);

    const handleStorageEvent = useCallback((event) => {
        dispatch(synchronizeTabs(event));
    }, [dispatch]);

    const processCentrifugeMessage = (message) => {
        const upstreamType = message.data.upstream;
        if (upstreamType === NOTIFICATION_UPSTREAM_TYPES.UPDATE) {
            dispatch(loadNotifications());
        } else {
            dispatch(notificationUpstream(message));
        }
    };

    const excludePages = pages => pages.some(path => window.location.pathname.startsWith(path));

    useEffect(() => {
        if (!window.USER_PROFILE) {
            return;
        }
        window.addEventListener('storage', handleStorageEvent);
        dispatch(loadNotifications());
        dispatch(getActualTheme()).then((data) => {
            if (data?.contentImage) {
                const contentDiv = document.getElementById('content');
                contentDiv.style.backgroundImage = `url(${data.contentImage})`;
                contentDiv.style.backgroundSize = '100%';
                contentDiv.style.backgroundRepeat = 'repeat-y';
            }
        });
        const userId = window.USER_PROFILE.id;
        if (userId && !window.USER_PROFILE.sou) {
            const channel = `notification-center-${userId}`;
            const centrifuge = getCentrifugeConnection();
            setCentrifugeSubscription(centrifuge.subscribe(channel, processCentrifugeMessage));
        }

        window.addEventListener('scroll', throttledScrollListener);

        // eslint-disable-next-line consistent-return
        return () => {
            window.removeEventListener('storage', handleStorageEvent);
            window.removeEventListener('scroll', throttledScrollListener);
            if (centrifugeSubscription) {
                centrifugeSubscription.unsubscribe();
                centrifugeSubscription.removeAllListeners();
            }
        };
        // eslint-disable-next-line
    }, []);

    const addNotification = (obj) => {
        if (notificationSystem.current) {
            return notificationSystem.current.addNotification({
                ...obj,
                dismissible: false,
            });
        }
        return null;
    };

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        const {
            title, message, level, children,
        } = notifications;
        addNotification({
            title, message, level, children,
        });
        // eslint-disable-next-line
    }, [notifications.id]);

    const handleBurgerToggle = () => {
        document.body.style.overflow = (!menuIsOpen || mobileBellIsOpen) ? 'hidden' : 'auto';
        setMenuIsOpen(prev => !prev);
    };

    const handleMobileBell = () => {
        document.body.style.overflow = !mobileBellIsOpen ? 'hidden' : 'auto';
        setMobileBellIsOpen(prev => !prev);
    };

    const changeLocale = (value) => {
        const { value: locale } = value;
        callApi(changeLocaleUrl(locale)).then(() => {
            window.location.reload();
        }).catch(() => {
            showErrorNotification('Произошла ошибка');
        });
    };

    if (excludePages(WITHOUT_HEADER)) {
        const headerWrapper = document.getElementById('header-wrapper');
        headerWrapper.style.height = '0';
        return null;
    }

    const headerPositionTop = headerIsShown ? { top: 0 } : { top: isMobile ? -56 : -72 };
    const headerBackground = headerImageUrl ? {
        backgroundImage: `url(${headerImageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100% 100%',
    } : {};
    const headerStyle = {
        ...headerPositionTop,
        ...(isMobile ? {} : headerBackground),
        borderBottom: '1px solid #e0e0e0',
        height: isMobile ? 56 : 72,
    };

    return (
        <div>
            <AuthError />
            <Header
                className={ classNames({ 'intdev-ui-header': true }) }
                logo={ <HeaderLogo src={ window.LOGO_URL } /> }
                bellEventsCount={ window.USER_PROFILE && newNotificationsCount }
                user={ window.USER_PROFILE }
                bellContent={ window.USER_PROFILE && (
                    <div>
                        <NotificationsContainer />
                    </div>
                ) }
                theme={ headerThemeNamePrivate }
                headerStyle={ headerStyle }
                isMobile={ isMobile }
                openMobileBell={ handleMobileBell }
                bellIcon={ <BellIcon /> }
                drawerMenu={ window.USER_PROFILE && (
                    <div style={ { padding: '0 20px 0 0' } }>
                        <MenuDrawer />
                    </div>
                ) }
                additionalMenu={ window.USER_PROFILE && (
                    <div style={ { padding: '0 16px 0 0' } }>
                        <AdditionalMenu />
                    </div>
                ) }
            >
                { isMobile
                    ? (!!window.USER_PROFILE && (
                        <>
                            <MenuToggleButton
                                onClick={ handleBurgerToggle }
                                menuIsOpen={ menuIsOpen }
                            />
                            { menuIsOpen && (
                                <MenuComponent
                                    navigationMenu={ menu }
                                    handleCloseMenu={ handleBurgerToggle }
                                    changeLocale={ window.USER_PROFILE && changeLocale }
                                />
                            )}
                            <MobileNotificationContainer
                                isOpen={ mobileBellIsOpen }
                                closeMobileBell={ handleMobileBell }
                            >
                                <NotificationsContainer
                                    closeBell={ handleMobileBell }
                                />
                            </MobileNotificationContainer>
                        </>
                    ))
                    : (Boolean(window.USER_PROFILE)) && (
                        <>
                            <HeaderSearch onItemSelected={ defaultOnToPage } />
                            {/* <SelectLocale changeLocale={ window.USER_PROFILE && this.changeLocale } /> */}
                        </>
                    )}
            </Header>
            <NotificationSystem ref={ notificationSystem } />
        </div>
    );
};
