import { matchPath } from 'react-router-dom';
import { getHistory } from '../history';
import { pages } from '../pages';

const history = getHistory();

const checkLink = (link) => {
    if (!link) {
        return false;
    }
    const { target } = link;
    if (!target || target === '_self') {
        return true;
    }
    if (target === '_blank') {
        return false;
    }
    if (target === '_parent' || target === '_top') {
        return window.top === window;
    }
    return false;
};

export const goOnSpaUrl = (url, event = null) => {
    const { href, origin, pathname } = window.location;
    const urlData = new URL(url, href);
    const pageUrl = urlData.href.replace(/#.*/, '');
    const currentPageUrl = href.replace(/#.*/, '');
    if (
        pageUrl !== currentPageUrl
        && urlData.origin === origin
        && pages.find(p => matchPath(urlData.pathname, p.route)) // dst URL in SPA
        && pages.find(p => matchPath(pathname, p.route)) // current URL in SPA
    ) {
        if (event) {
            event.preventDefault();
        }
        const newUrl = urlData.toString().replace(urlData.origin, '');
        history.push(newUrl);
        return true;
    }
    return false;
};

export const handleAtagClick = (event) => {
    if (
        (event.which && event.which !== 1)
        || event.ctrlKey || event.shiftKey || event.metaKey || event.altKey
        || event.defaultPrevented
    ) return;

    const el = event.target.closest('a');
    if (checkLink(el)) {
        const url = el.getAttribute('href');
        if (!url) return;
        goOnSpaUrl(url, event);
    }
};
