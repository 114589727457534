import React from 'react';
import PropTypes from 'prop-types';
import {
    Button, TextField, Upload, TooltipContainer,
} from 'intdev-ui';

import { callApi } from '@/common/middlewares/apiMiddleware';
import { createEmailUrl } from '@/user_detail/apiUrls';
import { showErrorNotification } from '@/common/helpers/showNotification';
import { imageUploadUrl } from '@/common/constants/apiUrls';
import { FORMDATA } from '@/common/constants/ajaxBodyTypes';
import { objCamelFromSnake } from '@/common/helpers/objCamelFromSnake';

const footerStyle = {
    marginTop: 25,
};
const buttonStyle = {
    marginLeft: 10,
};

const enterKeyCode = 13;

const ERROR_MSG = 'К сожалению, произошла ошибка';

const allowedExtensions = ['.jpg', '.jpeg', '.png', '.gif'];

export class EmailForm extends React.Component {
    static propTypes = {
        // eslint-disable-next-line
        currentOption: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        onCloseModal: PropTypes.func.isRequired,
        setHasUnsaved: PropTypes.func.isRequired,
    };

    state = {
        subject: null,
        text: null,
        creatingTask: false,
        emailSent: false,
        errorMessage: null,
        errors: {},
        attachments: [],
        isUploadingFile: false,
    };

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeydown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeydown);
    }

    getErrorType = fieldName => (this.state.errors[fieldName] ? 'error' : null);

    getFormData = () => {
        const { subject, attachments, text } = this.state;

        return {
            subject,
            attachments,
            text: text,
            url: window.location.href,
        };
    }

    validateForm = () => {
        let isValid = true;
        const requiredFields = ['text'];
        if (this.props.currentOption.allow_fill_subject) {
            requiredFields.push('subject');
        }

        for (const field of requiredFields) {
            if (!this.state[field]) {
                this.setState(prevState => ({
                    errors: {
                        ...prevState.errors,
                        [field]: 'Это поле обязательно',
                    },
                }));
                isValid = false;
            }
        }
        return isValid;
    };

    handleKeydown = (event) => {
        if (
            !this.state.creatingTask
            && (event.metaKey || event.ctrlKey) && event.keyCode === enterKeyCode
        ) {
            this.handleSendButton();
        }
    };

    handleFieldChange = fieldName => (ev) => {
        this.props.setHasUnsaved(true);

        const { value } = ev.target;
        this.setState((prevState) => {
            const errors = { ...prevState.errors };
            delete errors[fieldName];
            return {
                [fieldName]: value,
                errors,
                errorMessage: '',
            };
        });
    };

    handleSendButton = () => {
        const { currentOption } = this.props;

        const isValid = this.validateForm();
        if (!isValid) {
            return;
        }

        this.setState({
            creatingTask: true,
            errorMessage: '',
        });

        callApi(createEmailUrl(currentOption.id), 'post', this.getFormData()).then(() => {
            this.props.setHasUnsaved(false);
            this.setState({
                creatingTask: false,
                emailSent: true,
            });
        }).catch((data) => {
            showErrorNotification(data.error);

            this.setState({
                creatingTask: false,
                errorMessage: ERROR_MSG,
            });
        });
    };

    handleDropFile = (files) => {
        const file = files[0];
        const dotIndex = file.name.lastIndexOf('.');
        const fileExt = file.name.slice(dotIndex);
        if (!allowedExtensions.includes(fileExt)) {
            showErrorNotification('Формат файла не поддерживается');
            return;
        }
        this.setState({ isUploadingFile: true });
        callApi(imageUploadUrl, 'POST', { file }, FORMDATA).then((response) => {
            const camelizedFile = objCamelFromSnake(response);
            this.setState(state => ({
                attachments: [...state.attachments, camelizedFile],
                isUploadingFile: false,
            }));
        }).catch(() => {
            showErrorNotification('Произошла ошибка при загрузке файла');
        });
    }

    excludeFromState = id => () => {
        this.setState(state => ({
            attachments: state.attachments.filter(file => file.attachmentImageId !== id),
        }));
    }

    renderForm() {
        const { currentOption } = this.props;
        const { isUploadingFile, attachments } = this.state;

        const uploadLabel = (
            <>
                <span>Прикрепить файл</span>
                <TooltipContainer
                    tooltip={ `Файл формата ${allowedExtensions.join(', ')} не более 10 Мбайт` }
                    tooltipStyles={ {
                        width: '240px',
                        fontSize: '13px',
                        lineHeight: '1em',
                        color: 'grey',
                        textTransform: 'none',
                    } }
                    position="top_left"
                >
                    <i
                        className="fa fa-question-circle-o"
                        style={ { fontSize: '14px', cursor: 'help', marginLeft: '5px' } }
                    />
                </TooltipContainer>
            </>
        );

        return (
            <div>
                { !!currentOption.description
                    && (
                        <div
                            dangerouslySetInnerHTML={ { __html: currentOption.description } }
                            style={ { marginBottom: '15px' } }
                        />
                    )}
                { currentOption.allow_fill_subject
                    && (
                        <div className="form-field_el" style={ { marginBottom: '15px' } }>
                            <label>Заголовок письма</label>
                            <TextField
                                fullWidth
                                disabled={ this.state.creatingTask }
                                onChange={ this.handleFieldChange('subject') }
                                name="subject"
                                validationState={ this.getErrorType('subject') }
                                validationText={ this.state.errors.subject }
                            />
                        </div>
                    )}
                <div className="form-field_el task-form-textarea">
                    <label>Текст письма</label>
                    <TextField
                        fullWidth
                        multiLine
                        disabled={ this.state.creatingTask }
                        rows={ 5 }
                        rowsMax={ 15 }
                        onChange={ this.handleFieldChange('text') }
                        name="text"
                        validationState={ this.getErrorType('text') }
                        validationText={ this.state.errors.text || this.state.errorMessage }
                    />
                    { Boolean(attachments.length) && (
                        <div style={ { marginTop: 15 } }>
                            <p style={ { fontWeight: 'bold' } }>Загруженные файлы:</p>
                            { attachments?.map((file, index) => (
                                <div key={ file.attachmentImageId } style={ { marginBottom: 5 } }>
                                    <strong>{ index + 1 }.&nbsp;</strong>{ file.filename }
                                    <i
                                        className="fa fa-trash"
                                        style={ { marginLeft: 10, cursor: 'pointer', color: 'red' } }
                                        onClick={ this.excludeFromState(file.attachmentImageId) }
                                    />
                                </div>
                            )) }
                        </div>
                    ) }
                    <Upload
                        accept={ allowedExtensions.join(', ') }
                        onDrop={ this.handleDropFile }
                        disabled={ isUploadingFile }
                        style={ { marginTop: 15 } }
                        uploadLabel={ uploadLabel }
                    />
                </div>

                <div style={ footerStyle }>
                    <Button
                        label="Отправить"
                        onClick={ this.handleSendButton }
                        disabled={ this.state.creatingTask || isUploadingFile }
                        primary
                    />
                    <Button
                        style={ buttonStyle }
                        label="Отмена"
                        disabled={ this.state.creatingTask || isUploadingFile }
                        onClick={ this.props.onCancel }
                    />
                </div>
            </div>
        );
    }

    render() {
        if (!this.state.emailSent) {
            return this.renderForm();
        }

        return (
            <div className="task-form-result-container">
                <h1 className="task-form-result-header">
                    Письмо отправлено
                </h1>
                <Button
                    style={ buttonStyle }
                    onClick={ this.props.onCloseModal }
                    label="ОК"
                    primary
                />
            </div>

        );
    }
}
