export const styles = {
    footer: {
        marginTop: 25,
    },
    formRow: {
        marginTop: 20,
    },
    formRowMultipleInputs: {
        marginTop: 20,
        display: 'flex',
        justifyContent: 'space-between',
    },
    projectInput: {
        flex: '0 0 60%',
    },
    componentInput: {
        flex: '0 0 35%',
    },
    issueTypeInput: {
        flex: '0 0 40%',
    },
    labelInput: {
        flex: '0 0 55%',
    },
    label: {
        fontWeight: 'bold',
    },
    checkbox: {
        cursor: 'pointer',
    },
};
