import { urls as ideasUrls } from '@/ideas_pek/constants/urls';

export const getUserUrl = username => `/users/${username}/`;

export const getUserPageContextUrl = username => `/users/${username}/context/`;

export const getAvatarInfoUrl = username => `/user_avatar/${username}/info/`;

export const getAvatarUpdateUrl = username => `/user_avatar/${username}/update/`;

export const avatarDeleteUrl = avatarId => `/user_avatar/${avatarId}/nosaved_avatar_delete/`;

export const getAvatarUploadUrl = username => `/user_avatar/${username}/tmp/`;

export const getRecognizeUrl = userId => `/user_avatar/recognize_face_coords/${userId}/`;


// Jira Task Creation
export const getOptionsUrl = userId => `/jira/user_task_creation_options/${userId}/`;
export const getButtonOptionsUrl = buttonShortname => `/jira/button_task_creation_options/${buttonShortname}/`;
export const getButtonByShortname = buttonShortname => `/jira/get_button_by_shortname/${buttonShortname}/`;
export const createOptionUrl = '/jira/task_creation_options/';
export const updateOptionUrl = optionId => `/jira/task_creation_options/${optionId}/`;
export const deleteOptionUrl = optionId => `/jira/task_creation_options/${optionId}/`;
export const createEmailOptionUrl = '/jira/email_options/';
export const updateEmailOptionUrl = optionId => `/jira/email_options/${optionId}/`;
export const deleteEmailOptionUrl = optionId => `/jira/email_options/${optionId}/`;
export const getComponentOptionsUrl = '/jira/options_for_project/';
export const createTaskUrl = optionId => `/jira/create_task/${optionId}/`;
export const createEmailUrl = optionId => `/jira/create_email/${optionId}/`;

export const pagesUrls = {
    searchUrl: '/search/',
    quizFired: '/quiz/fire/',
    manageDataCenterListUrl: '/datacenter/people/',
    userAdminUrl: userId => `/admin/users/user/${userId}/`,
    userAvatarEditUrl: username => `/user_avatar/${username}/edit/`,
    thanksListPageUrl: username => `/thanks/${username}/`,
    storeUrl: '/store/',
    followersPageUrl: username => `/following/user/${username}/followers/`,
    followsPageUrl: username => `/following/user/${username}/follows/`,
    favoritesPageUrl: username => `/users/${username}/favorites/`,
    commentsPageUrl: username => `/users/${username}/comments/`,
    editProfilePageUrl: username => `/users/${username}/edit/`,
    userCallHistoryPageUrl: username => `/users/${username}/call_history/`,
    recommendationsPageUrl: '/vacancy/myfriends/referrals/',
    userPhotosPageUrl: username => `/users/${username}/photos/`,
    userMarksPageUrl: username => `/users/${username}/photos/marks/`,
    userRegularIdeasPageUrl: userId => ideasUrls.userIdeasPage(userId, false),
    userFranchiseeIdeasPageUrl: userId => ideasUrls.userIdeasPage(userId, true),
    userAssetsPageUrl: username => `/users/${username}/assets/`,
    userFiltersPageUrl: username => `/users/${username}/filters/`,
    userDocumentsUrl: sapId => `https://home.my.com/documents/${sapId}/`,
    badgeProgressPageUrl: userId => `/badges/user/${userId}/`,
    badgePageUrl: (badgeId, userId) => `/badges/${badgeId}/${userId ? `?user=${userId}` : ''}`,
    locationPageUrl: floorId => `/location/floor/${floorId}/`,
    cabinetPageUrl: cabinetId => `/location/cabinet/${cabinetId}/`,
    userOffencePageUrl: '/work_time/my_current_offence/',
    userLogsPageUrl: userId => `/users/logs/${userId}/last/`,
    userProfilePageUrl: username => `/users/${username}/`,
    unitPageUrl: unitId => `/structure/#unit${unitId}`,
    projectPageUrl: projectId => `/projects/${projectId}/`,
    stickersPageUrl: '/stickers/',
    izonePageUrl: '/izone/',
    userStatisticPageUrl: username => `/users/${username}/statistic/`,
    userSettingsPageUrl: username => `/users/${username}/settings/`,
    userMailingUrl: username => `/mailing/?show=all&username=${username}`,
    userActivityPageUrl: username => `/user_activity/${username}/`,
    office_request: '/office_request/',
};

export const apiUrls = {
    getUserMainInfoUrl: username => `/users/${username}/main_info/`,
    getUserInfoUrl: '/users/info/',
    getUserJobInfoUrl: username => `/users/${username}/job_info/`,
    getUserContactsInfoUrl: username => `/users/${username}/contacts_info/`,
    getUserBiographyInfoUrl: username => `/users/${username}/biography_info/`,
    getUserDataCenterInfoUrl: userId => `/datacenter/ajax/${userId}/`,
    getAvailabilityInfoUrl: username => `/users/${username}/availability_info/`,
    getUserFollowUrl: username => `/following/api/user/${username}/follow/`,
    getUserUnfollowUrl: username => `/following/api/user/${username}/unfollow/`,
    getUserFavoritesUrl: username => `/users/${username}/favorites/json/`,
    getUserCommentsUrl: username => `/users/${username}/comments/json/`,
    accessToQuizUrl: username => `/users/${username}/add_to_fire_questionnaire/`,
    alumniInviteUrl: username => `/users/${username}/alumni_invite/`,
    removeAchievementUrl: (username, badgeId) => `/badges/${username}/delete_badge/${badgeId}/`,
    mergeUsersUrl: '/users/merge/',
    sendSmsUrl: userId => `/we/sms/${userId}/single/`,
    getAdditionalInfoUrl: username => `/users/${username}/additional_info/`,
    getSkillsInfoUrl: userId => `/skills/profile/${userId}/list/`,
    inviteToWelcomeTrainingUrl: userId => `/users/invite_welcome/${userId}/`,
    updateRecognitionUrl: username => `/user_avatar/${username}/update_recognition/`,
    makeCallUrl: '/users/make_call/',
    toggleVacationVisibility: vacationId => `/users/toggle_vacation_visibility/${vacationId}/`,
    getUserAssetsList: username => `/users/${username}/assets/list/`,
    changeStatus: userId => `/users/${userId}/status/`,
    getUserCoinTransitions: userId => `/store/${userId}/operations/`,
    getUserCountersInfoUrl: username => `/users/${username}/counters_info/`,
    getBadgesListMayRemovedUrl: username => `/badges/user/${username}/may_removed_list/`,
    getUserStatsDataUrl: username => `/users/${username}/statistic/data/`,
    getUserFilters: username => `/users/${username}/filters/json/`,
    deleteUserFilter: filterId => `/mailing/delete_filter/${filterId}/`,
    followUserFilter: filterId => `/following/api/filter/${filterId}/follow/`,
    unfollowUserFilter: filterId => `/following/api/filter/${filterId}/unfollow/`,
    deleteAvatars: username => `/user_avatar/${username}/main-avatar/delete/`,
    getSwapCompetenceOptions: type => username => ({
        badge: `/badges/by-user/${username}/swap-achievement/`,
        thanks: `/thanks/by-user/${username}/swap-thanks/`,
    })[type],
    swapCompetence: type => (username, objectId) => ({
        badge: `/badges/by-user/${username}/swap-achievement/${objectId}/`,
        thanks: `/thanks/by-user/${username}/swap-thanks/${objectId}/`,
    })[type],
    getRemoveThanksCompetenceOptions: (username, slug) => `/thanks/by-user/${username}/?thanks_type__slug=${slug}`,
    removeThanksCompetence: (username, thanksId) => `/thanks/by-user/${username}/${thanksId}`,
    getRemoveAchievementOptions: (username, badgeId) => `/badges/by-user/${username}/remove-achievement/?badge_id=${badgeId}`,
    removeAchievementById: (username, achievementId) => `/badges/by-user/${username}/remove-achievement/${achievementId}/`,
};
