import React from 'react';
import { Select } from 'intdev-ui';
import { SettingsIcon } from '@/services_common/components/SettingsIcon';
import classes from './OwnGroupSelect.scss';

export const OwnGroupSelect = () => {
    const [ownGroup, setOwnGroup] = React.useState(window.USER_PROFILE.currentOwnGroup);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        if (ownGroup !== window.USER_PROFILE.currentOwnGroup) {
            window.location.href = `/users/change_own_group/${ownGroup}/`;
            setIsLoading(true);
        }
    }, [ownGroup]);

    return (
        !!window.USER_PROFILE?.canChangeOwnGroup
        && !!window.USER_PROFILE.ownGroupOptions?.length
        && (
            <div className={ classes.container }>
                <div className={ classes.labelContainer }>
                    <SettingsIcon />
                    <div className={ classes.label }>Роль пользователя</div>
                </div>
                <Select
                    options={ window.USER_PROFILE.ownGroupOptions.map(([value, label]) => ({ value, label })) }
                    value={ ownGroup }
                    onChange={ ({ value }) => setOwnGroup(value) }
                    disabled={ isLoading }
                    fullWidth
                    editable={ false }
                    clearable={ false }
                    labelStyle={ { color: '#82818e' } }
                    style={ { borderRadius: 4 } }
                />
            </div>
        )
    );
};
