import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@/base_page/assets/CloseButton.svg';
import LogoutIcon from '@/base_page/assets/logout.svg';
import SearchIcon from '@/base_page/assets/search-normal.svg';
import SettingsIcon from '@/base_page/assets/settings.svg';
import { dictionary } from '@/common/localization/dictionary';
import { UserInfo } from '@/base_page/common/UserInfo';
import { BaseMenu } from '@/base_page/components/BaseMenu';
import { LocaleDropdown } from '@/services_common/components/LocaleDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { headerMenuSelector } from '@/base_page/selectors';
import { isEmpty } from 'lodash-es';
import { getMenu } from '@/base_page/actions/actions';
import { filterMenuByPerms } from '@/base_page/helpers';

export const MenuComponent = ({ changeLocale, handleCloseMenu }) => {
    const dispatch = useDispatch();
    const menu = useSelector(headerMenuSelector);

    useEffect(() => {
        if (isEmpty(menu.menu)) dispatch(getMenu());
    }, []);

    const filteredMenu = filterMenuByPerms(menu);

    return (
        <div className="menu-component-container">
            <div className="menu-component-header">
                <LocaleDropdown handleSelectLocale={ changeLocale } />
                <button type="button" onClick={ handleCloseMenu } className="menu-component-close-btn">
                    <img src={ CloseIcon } alt="закрыть" />
                </button>
            </div>
            <div className="menu-component-content">
                <div>
                    <UserInfo handleClose={ handleCloseMenu } />
                </div>
                <div className="menu-component-main">
                    <BaseMenu navigationMenu={ filteredMenu } handleCloseMenu={ handleCloseMenu } />
                    <div style={ { display: 'flex', flexDirection: 'column' } }>
                        <a
                            href={ `${window.USER_PROFILE.url}settings/` }
                            className="main-menu-item"
                            key="settings"
                            onClick={ handleCloseMenu }
                        >
                            <img src={ SettingsIcon } alt="настройки" style={ { marginRight: 5 } } />
                            <span>{ dictionary.settings }</span>
                        </a>
                        <a
                            href="/go/"
                            className="main-menu-item"
                            key="search"
                            onClick={ handleCloseMenu }
                        >
                            <img src={ SearchIcon } alt="поиск" style={ { marginRight: 5 } } />
                            <span>{ dictionary.search }</span>
                        </a>
                        <a
                            href="/logout/"
                            className="main-menu-item"
                            key="exit"
                        >
                            <img src={ LogoutIcon } alt="выход" style={ { marginRight: 5 } } />
                            <span>{ dictionary.logout }</span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

MenuComponent.propTypes = {
    handleCloseMenu: PropTypes.func.isRequired,
    changeLocale: PropTypes.func.isRequired,
};
