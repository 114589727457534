import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Popover, A } from 'intdev-ui';

export class CallPopover extends Component {
    static propTypes = {
        isOpenPopover: PropTypes.bool.isRequired,
        requestCall: PropTypes.func.isRequired,
        onRequestClose: PropTypes.func.isRequired,
        anchorEl: PropTypes.instanceOf(Element),
        devices: PropTypes.arrayOf(PropTypes.shape({
            model: PropTypes.string,
            name: PropTypes.string.isRequired,
            desc: PropTypes.string,
        })).isRequired,
        popoverProps: PropTypes.shape(),
    };

    static defaultProps = {
        anchorEl: null,
        popoverProps: {},
    };

    render() {
        const devices = this.props.devices;
        const choices = [];
        for (const device of devices) {
            let deviceDescription;
            let deviceName;
            if (device.name !== 'mobile_phone') {
                deviceDescription = `Офисный телефон ${device.desc}`;
            } else {
                deviceDescription = device.desc;
            }
            const deviceModel = device.model;
            if (device.name && device.name !== 'mobile_phone') {
                deviceName = device.name;
            } else {
                deviceName = '';
            }

            const deviceInfo = (deviceName || deviceModel)
                ? (
                    <div style={ { fontSize: '13px', color: 'rgb(216, 216, 216)', paddingTop: 2, paddingBottom: 5 } }>
                        <span>{ deviceModel }</span> <span>{ deviceName }</span>
                    </div>
                )
                : null;

            const phone = (
                <div key={ deviceDescription } >
                    <div>
                        <A
                            style={ {
                                fontSize: '15px',
                                cursor: 'pointer',
                                color: 'rgb(216, 216, 216)',
                                textDecoration: 'none',
                                borderBottom: '1px solid rgb(216, 216, 216)',
                            } }
                            onClick={ () => this.props.requestCall(device.name) }
                        >
                            <span style={ { color: 'rgb(51, 51, 51)' } }>{ deviceDescription }</span>
                        </A>
                    </div>
                    { deviceInfo }
                </div>
            );
            choices.push(phone);
        }
        return (
            <Popover
                { ...this.props.popoverProps }
                anchorEl={ this.props.anchorEl }
                anchorOrigin={ { horizontal: 'left', vertical: 'bottom' } }
                targetOrigin={ { horizontal: 'left', vertical: 'top' } }
                open={ this.props.isOpenPopover }
                onRequestClose={ this.props.onRequestClose }
            >
                <div style={ { padding: 15 } }>{ choices }</div>
            </Popover>
        );
    }
}
