import React from 'react';
import PropTypes from 'prop-types';
import { A } from 'intdev-ui';

export const HeaderLogo = ({ src }) => (
    <A href="/">
        <img src={ src } id="logo-image" alt="logo" />
    </A>
);

HeaderLogo.propTypes = {
    src: PropTypes.string,
};

HeaderLogo.defaultProps = {
    src: undefined,
};
