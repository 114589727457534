export const apiUrls = {
    getNotifications: '/notification_center/get_notifications/',
    getNotificationList: page => `/notification_center/all_notifications/?page=${page}`,
    markSeenAll: '/notification_center/mark_seen_all/',
    markReadAll: '/notification_center/mark_read_all/',
    markRead: '/notification_center/mark_read/',
};

export const applicationUrls = {
    settings: '/me/settings/',
};
