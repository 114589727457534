import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { getHistory } from '@/spa/history';
import { CustomProvider } from '@/common/components/CustomProvider';
import { render } from '@/common/helpers/render';
import { WeHeader } from './components/WeHeader';
import { getStore } from './helpers/createStore';
import { ReportNScrollComponent } from './components/ReportScroll';

const renderHeader = () => {
    const container = document.getElementById('header-wrapper');
    if (container) {
        const history = getHistory();
        render(
            <CustomProvider store={ getStore() }>
                <ConnectedRouter history={ history }>
                    <WeHeader />
                </ConnectedRouter>
            </CustomProvider>,
            container,
        );
    }
};

const renderReportErrorButton = () => {
    const container = document.getElementById('report_error_wrapper');
    if (container) {
        render(<ReportNScrollComponent />, container);
    }
};

document.addEventListener('DOMContentLoaded', () => {
    renderHeader();
    renderReportErrorButton();
});
