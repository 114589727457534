/**
 * Импортируем страницы через React.lazy. Так как lazy не поддерживает именованные импорты,
 * файл должен экспортировать компонент через default: (Eg.) export default Page;
 * Внутри приложений писать собственный роутер нет необходимости. Можно сразу использовать
 * Switch со своими рутами, так как приложение уже завернуто в роутер.
 *
 * @param {function} reducers
 *      Импортируемые редусеры подключаются в единый spaStore. Так же, как и с самим компонентом,
 *      следует экспортировать редюсеры через default (см. примеры)
 *
 * @param {function} configs
 *      Под конфигом подразумевается объект, который будет прокинут в defaultParams
 *      в момент рендера конкретного приложения.
 *      Также экспортируется через ключевое слово default (см. примеры). Дальше в приложении
 *      можно ретривить прокинутые параметры через соответствующие редюсеры(userPermission, features,....)
 *
 * @param {string} documentTitle
 *      Будет выставлен после "ленивой" подгрузки приложения
 *      (при переключении между приложениями в спа, title страницы сам поменяться не может)
 *
 * @param {string} webpackChunkName
 *      Необязательный параметр, но лучше его выставить для более удобного дебага chunkLoadError.
 *      Только смотрим, чтобы webpackChunkName был уникальным и не пересекался с чанками из других разделов,
 *      например, webpack_config/entries. там chunkName = ключу объекта.
 *
 * @param {boolean} showReportButton
 *      Показывать кнопку "Нашли ошибку" на страницах приложения или нет
 *
 *  NB: предупреждение насчет LOCATION_CHANGE.
 *      При загрузке нового приложения, мы добавляем наши редюсеры в спа стор. Для этого используется ф-я
 *      replaceReducer, которая пересоздает нужные объекты в сторе и вызывает для них INIT(https://redux.js.org/recipes/code-splitting)
 *      Так как объект истории у нас один на все приложение, это значит, что после подгрузки нового приложения редакс
 *      по-новой проходится по всей истории пушей в объект истории. Поэтому в редюсерах изменять стейт, подписываясь на
 *      LOCATION_CHANGE -- плохая идея. Лучше в самом компоненте в хуке didUpdate вызывать нужные экшены.
 *      Если уж и используем, проверяем, чтобы нормально работало при переходе между приложениями.
 */

export const pages = [
    {
        route: {
            path: '/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "main_page_spa" */ '../main_page/spaIndex'),
        reducers: () => import(/* webpackChunkName: "main_page_reducers" */ '../main_page/reducers'),
        documentTitle: 'Главная',
    },
    {
        route: {
            path: '/discounts/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "discounts" */ '../discounts/spaIndex'),
        reducers: () => import(/* webpackChunkName: "discounts_reducers" */ '../discounts/store'),
        configs: () => import(/* webpackChunkName: "discounts_configs" */ '../discounts/constants/defaultParams'),
        documentTitle: 'Скидки',
    },
    {
        route: {
            path: '/users/timeline/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "user_timeline" */ '../timeline/spaIndex'),
        reducers: () => import(/* webpackChunkName: "user_timeline_reducers" */ '../timeline/reducers'),
        documentTitle: 'Твой таймлайн',
    },
    {
        route: {
            path: '/users/:username/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "user_detail_spa" */ '../user_detail/spaIndex'),
        reducers: () => import(/* webpackChunkName: "user_detail_reducers" */ '../user_detail/reducers'),
    },
    {
        route: {
            path: '/users/:username/statistic/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "user_statistic" */ '../user_detail/components/statistic/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/call_history/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "call_history" */ '../call_history/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/profile_history/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "profile_history" */ '../profile_history/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/favorites/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "users_favorites" */ '../user_detail/components/favorites/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/comments/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "users_comments" */ '../user_detail/components/comments/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/assets/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "users_assets" */ '../user_detail/components/assets/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/filters/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "user_filters" */ '../user_detail/components/filters/spaIndex'),
    },
    {
        route: {
            path: ['/users/:username/settings/', '/me/settings/'],
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "user_settings" */ '../user_settings/spaIndex'),
        documentTitle: 'Настройки',
    },
    {
        route: {
            path: '/users/:username/edit/*',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "user_edit_spa" */ '../user_edit/spaIndex'),
        reducers: () => import(/* webpackChunkName: "user_edit_reducers" */ '../user_edit/reducers'),
        configs: () => import(/* webpackChunkName: "user_edit_configs" */ '../user_edit/constants/spaParams'),
        documentTitle: 'Редактирование профиля',
    },
    {
        route: {
            path: '/timeline/micropost/:postId/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "micropost_single_spa" */ '../micropost/spa/indexSingle'),
        reducers: () => import(/* webpackChunkName: "micropost_single_reducers" */ '../micropost/spa/reducers'),
    },
    {
        route: {
            path: '/timeline/micropost/:postId/edit/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "micropost_edit" */ '../micropost/spa/indexEdit'),
        reducers: () => import(/* webpackChunkName: "micropost_edit_reducers" */ '../micropost/spa/reducers'),
    },
    {
        route: {
            path: '/user_avatar/:username/edit/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "users_edit_avatar" */'../user_detail/avatar_editor/spaIndex'),
    },
    {
        route: {
            path: '/users/:username/photos/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: "myfriends_reducers" */ '../user_photos/reducers'),
        loader: () => import(/* webpackChunkName: "users_favorites" */ '../user_photos/spaIndex'),
    },
    {
        route: {
            path: '/badges/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "badges" */ '../badge/spaIndex'),
        documentTitle: 'Значки',
    },
    {
        route: {
            path: '/skills/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "skills_spa" */ '../skills/spaIndex'),
        documentTitle: 'Навыки',
    },
    {
        route: {
            path: '/faq/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "faq" */ '../faq/spaIndex'),
        reducers: () => import(/* webpackChunkName: "faq_reducers" */ '../faq/reducers'),
        documentTitle: 'F.A.Q.',
    },
    {
        route: {
            path: '/stickers/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "stickers" */ '../stickers/spaIndex'),
        reducers: () => import(/* webpackChunkName: "stickers_reducers" */ '../stickers/reducers'),
        documentTitle: 'Стикеры',
    },
    {
        route: {
            path: '/likes/top_authors/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "top_authors" */ '../top_authors/spaIndex'),
        configs: () => import(/* webpackChunkName: "top_authors_reducers" */ '../top_authors/defaultParamConfig'),
    },
    {
        route: {
            path: '/stats/statistic/ctype/:ctId/obj/:objId/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "record_stats" */ '../stats/spaIndex'),
        documentTitle: 'Статистика записи',
    },
    {
        route: {
            path: '/following/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "following" */ '../following/spaIndex'),
        reducers: () => import(/* webpackChunkName: "following_reducers" */ '../following/reducers'),
        configs: () => import(/* webpackChunkName: "following_configs" */ '../following/defaultParamsConfig'),
    },
    {
        route: {
            path: '/users/:username/assets/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "users_assets" */ '../user_detail/components/assets/spaIndex'),
        documentTitle: 'Оборудование',
    },
    {
        route: {
            path: '/user_avatar/moderate/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "avatar_moderation" */ '../avatar/spaIndex'),
        documentTitle: 'Модерация фото',
    },
    {
        route: {
            path: '/events/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "public_events_spa" */ '../public_events/spaIndex'),
        reducers: () => import(/* webpackChunkName: "public_events_reducers" */ '../public_events/reducers'),
        documentTitle: 'Календарь мероприятий',
    },
    {
        route: {
            path: '/team_news/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "team_news_spa" */ '../team_news/spaIndex'),
        reducers: () => import(/* webpackChunkName: "team_news_reducers" */ '../team_news/reducers'),
        configs: () => import(/* webpackChunkName: "team_news_configs" */ '../team_news/constants/spaConfig'),
        documentTitle: 'Новости команды',
    },
    {
        route: {
            path: '/awards/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "nomination" */ '../nomination/spaIndex'),
        reducers: () => import(/* webpackChunkName: "nomination_reducers" */ '../nomination/reducers'),
        documentTitle: 'People awards',
    },
    {
        route: {
            path: '/vacancy/myfriends/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "myfriends_spa" */ '../myfriends/spaIndex'),
        reducers: () => import(/* webpackChunkName: "myfriends_reducers" */ '../myfriends/reducers'),
        documentTitle: 'myFriends',
    },
    {
        route: {
            path: '/thanks/:username/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "users_thanks" */ '../thanks/spaIndex'),
        reducers: () => import(/* webpackChunkName: "users_thanks_reducers" */ '../thanks/reducers'),
        documentTitle: 'Благодарности',
    },
    {
        route: {
            path: '/manual/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "manual_spa" */ '../manual/spaIndex'),
        reducers: () => import(/* webpackChunkName: "manual_reducers" */ '../manual/reducers'),
        documentTitle: 'Мануалы',
    },
    {
        route: {
            path: '/timeline/tagged/:tag/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "timeline_tagged_spa" */ '../timeline/tag'),
    },
    {
        route: {
            path: '/timeline/record/:recordId/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "timeline_single_spa" */ '../timeline/single'),
        documentTitle: 'Запись',
    },
    {
        route: {
            path: '/projects/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "projects_spa" */ '../projects/spaIndex'),
        reducers: () => import(/* webpackChunkName: "projects_reducers" */ '../projects/reducers'),
        configs: () => import(/* webpackChunkName: "projects_configs" */ '../projects/constants/spaConfig'),
        documentTitle: 'Проекты',
    },
    {
        route: {
            path: '/snippets/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "snippets" */ '../snippets/spaIndex'),
        reducers: () => import(/* webpackChunkName: "snippets_reducers" */ '../snippets/reducers'),
        configs: () => import(/* webpackChunkName: "snippets_configs" */ '../snippets/consts/spaConfig'),
        documentTitle: 'Сниппеты',
    },
    {
        route: {
            path: '/advice/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "advice_admin_panel" */ '../advice/spaIndex'),
        reducers: () => import(/* webpackChunkName: "advice_admin_panel_reducers" */ '../advice/reducers'),
        configs: () => import(/* webpackChunkName: "advice_admin_panel_configs" */ '../advice/constants/spaConfig'),
        documentTitle: 'Советы',
    },
    {
        route: {
            path: '/notification_popover/',
            exact: true,
        },
        loader: () => import('../notifications_popover/spaIndex'),
        // reducers: () => import(/* webpackChunkName: "advice_admin_panel_reducers" */ '../notifications_popover/reducers'),
        // configs: () => import(/* webpackChunkName: "advice_admin_panel_configs" */ '../notifications_popover/constants/spaConfig'),
        documentTitle: 'Оповещения',
    },
    {
        route: {
            path: '/perm_admin/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "perm_admin" */ '../perm_admin/spaIndex'),
        reducers: () => import(/* webpackChunkName: "perm_admin_reducers" */ '../perm_admin/reducers'),
        configs: () => import(/* webpackChunkName: "perm_admin_config" */ '../perm_admin/constants/spaConfig'),
        documentTitle: 'Административный интерфейс редактирования прав пользователей',
    },
    {
        route: {
            path: '/izone/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "izone" */ '../izone/spaIndex'),
        documentTitle: 'Анализатор веса',
    },
    {
        route: {
            path: '/todo/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "todo" */ '../todo/spaIndex'),
        reducers: () => import(/* webpackChunkName: "todo_reducers" */ '../todo/reducers'),
        configs: () => import(/* webpackChunkName: "todo_configs" */ '../todo/constants/spaConfig'),
        documentTitle: 'Задачи',
    },
    {
        route: {
            path: '/dashboards/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "dashboard" */ '../dashboard/spaIndex'),
        // Редусеры dashboards сразу подключаются в spaReducers (для работы GlobalDashboard)
        configs: () => import(/* webpackChunkName: "dashboard_configs" */ '../dashboard/spaConfig'),
        documentTitle: 'Дашборд',
    },
    {
        route: {
            path: '/reign/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "reign" */ '../reign/spaIndex'),
        configs: () => import(/* webpackChunkName: "reign_configs" */ '../reign/constants/spaConfig'),
        documentTitle: 'Администрирование',
    },
    {
        route: {
            path: '/games/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "games" */ '../games/spaIndex'),
        reducers: () => import(/* webpackChunkName: "games_reducers" */ '../games/store/reducers'),
        documentTitle: 'Игры',
    },
    {
        route: {
            path: '/location/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "location" */ '../location/spaIndex'),
        documentTitle: 'Карта кабинетов',
    },
    {
        route: {
            path: '/constants/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "constants_edit" */ '../constants_edit/spaIndex'),
        reducers: () => import(/* webpackChunkName: "constants_edit_reducers" */ '../constants_edit/reducers'),
        documentTitle: 'Редактирование настроек',
    },
    {
        route: {
            path: '/library/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'library' */ '../library/spaIndex'),
        reducers: () => import(/* webpackChunkName: 'library_reducers' */ '../library/store/reducers'),
        configs: () => import(/* webpackChunkName: 'library_configs' */ '../library/constants/spaConfig'),
        showReportButton: false,
        documentTitle: 'Библиотека',
    },
    {
        route: {
            path: '/confroom/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "confrooms" */ '../confrooms/spaIndex'),
        reducers: () => import(/* webpackChunkName: "confrooms_reducers" */ '../confrooms/reducer'),
        documentTitle: 'Бронирование переговорных',
    },
    {
        route: {
            path: '/confroom-strict/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "confrooms" */ '../confrooms/spaIndex'),
        reducers: () => import(/* webpackChunkName: "confrooms_reducers" */ '../confrooms/reducer'),
        documentTitle: 'Бронирование переговорных',
        showReportButton: false,
    },
    {
        route: {
            path: ['/post/', '/post/free/', '/post/archive/', '/post/restante/'],
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "post" */ '../post/spaIndex'),
        reducers: () => import(/* webpackChunkName: "post_reducers" */ '../post/reducers'),
        configs: () => import(/* webpackChunkName: "post_configs" */ '../post/consts/spaConfig'),
        documentTitle: 'Корреспонденция',
    },
    {
        route: {
            path: '/structure/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "structure" */ '../structure/spaIndex'),
        configs: () => import(/* webpackChunkName: "structure_configs" */ '../structure/spaConfig'),
        documentTitle: 'Структура',
    },
    {
        route: {
            path: '/real_structure/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "structure" */ '../structure/spaIndex'),
        configs: () => import(/* webpackChunkName: "structure_configs" */ '../structure/spaConfig'),
        documentTitle: 'Организационная структура',
    },
    {
        route: {
            path: '/structure/truncated/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "structure" */ '../structure/spaIndex'),
        configs: () => import(/* webpackChunkName: "structure_configs" */ '../structure/spaConfig'),
        documentTitle: 'Организационная структура',
    },

    {
        route: {
            path: '/go/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "full_text_search" */ '../search/spaIndex'),
        documentTitle: 'Поиск',
    },
    {
        route: {
            path: '/orion/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'orion' */ '../orion/spaIndex'),
        documentTitle: 'Заявки',
    },
    {
        route: {
            path: '/search/',
            exact: true,
        },
        loader: () => import(/* webpackChunkName: "search" */ '../user_search/spaIndex'),
        reducers: () => import(/* webpackChunkName: "search_reducers" */ '../user_search/reducers'),
        configs: () => import(/* webpackChunkName: "search_configs" */ '../user_search/spaConfig'),
        documentTitle: 'Поиск сотрудников',
    },
    {
        route: {
            path: '/likes/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "likes_spa" */ '../likes/spaIndex'),
        documentTitle: 'Топ лайков',
    },
    {
        route: {
            path: '/store/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "store_spa" */ '../store/spaIndex'),
        reducers: () => import(/* webpackChunkName: "store_reducers" */ '../store/reducers'),
        configs: () => import(/* webpackChunkName: "store_configs" */ '../store/constants/spaParams'),
        documentTitle: 'Магазин',
    },
    {
        route: {
            path: '/saml2/idp/applications/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: "saml2_applications" */ '../saml2/spaIndex'),
        documentTitle: 'Приложения SAML2',
    },
    {
        route: {
            path: '/exit_interview/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'exit_interview' */ '../exit_interview/spaIndex'),
        reducers: () => import(/* webpackChunkName: 'exit_interview_reducers' */ '../exit_interview/reducers'),
        configs: () => import(/* webpackChunkName: 'exit_interview_configs' */ '../exit_interview/spaConfig'),
        documentTitle: 'Опрос о работе в компании',
    },
    {
        route: {
            path: '/user_activity/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'user_activity' */ '../user_activity/spaIndex'),
        reducers: () => import(/* webpackChunkName: 'user_activity_reducers' */ '../user_activity/reducers'),
        configs: () => import(/* webpackChunkName: 'user_activity_configs' */ '../user_activity/spaConfig'),
        documentTitle: 'Активность сотрудника',
    },
    {
        route: {
            path: '/telegram_bot/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'telegram_bot' */ '../telegram_bot/spaIndex'),
        reducers: () => import(/* webpackChunkName: 'telegram_bot_reducers' */ '../telegram_bot/store/reducers'),
        documentTitle: 'Телеграм бот',
    },
    {
        route: {
            path: '/albums/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: "albums_reducers" */ '../albums/reducers'),
        loader: () => import(/* webpackChunkName: "albums_spa" */ '../albums/spaIndex'),
        configs: () => import(/* webpackChunkName: "albums_configs" */ '../albums/spaConfig'),
        documentTitle: 'Альбомы',
    },
    {
        route: {
            path: '/sitemap/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'sitemap' */ '../sitemap/spaIndex'),
        documentTitle: 'Карта сайта',
    },
    {
        route: {
            path: '/contests/zls_map/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'zls_map' */ '../zls_map/spaIndex'),
        documentTitle: 'Карта ЗЛС',
        showReportButton: false,
    },
    {
        route: {
            path: '/contests/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: "contests" */ '../contests/reducers'),
        configs: () => import(/* webpackChunkName: "contests" */ '../contests/constants/spaConfig'),
        loader: () => import(/* webpackChunkName: 'contests' */ '../contests/spaIndex'),
        documentTitle: 'Конкурсы',
    },
    {
        route: {
            path: '/notification_center/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: "notifications" */ '../notifications/reducers/spaReducers'),
        loader: () => import(/* webpackChunkName: 'notifications' */ '../notifications/spaIndex'),
        documentTitle: 'Уведомления',
    },
    {
        route: {
            path: '/mypec/',
            exact: false,
        },
        loader: () => import(/* webpackChunkName: 'mypec' */ '../mypec/spaIndex'),
        documentTitle: 'ПЭК',
    },
    {
        route: {
            path: '/service_report/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: "filial-stats" */ '../service_report/reducers'),
        loader: () => import(/* webpackChunkName: 'filial-stats' */ '../service_report/spaIndex'),
        configs: () => import(/* webpackChunkName: 'filial-stats' */ '../service_report/spaConfig'),
        documentTitle: 'Статистика филиала',
    },
    {
        route: {
            path: '/unit_page',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: "departments" */ '../departments/reducers/index'),
        loader: () => import(/* webpackChunkName: 'departments' */ '../departments/spaIndex'),
        documentTitle: 'Подразделения',
    },
    {
        route: {
            path: '/service-design/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'service_design' */ '../service_design/reducers'),
        loader: () => import(/* webpackChunkName: 'service_design' */ '../service_design/spaIndex'),
        configs: () => import(/* webpackChunkName: 'service_design' */ '../service_design/spaConfig'),
        documentTitle: 'Сервис-дизайн',
    },
    {
        route: {
            path: '/service-sincere/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'sincere_service' */ '../sincere_service/reducers'),
        loader: () => import(/* webpackChunkName: 'sincere_service' */ '../sincere_service/spaIndex'),
        documentTitle: 'Искренний сервис',
    },
    {
        route: {
            path: '/about_company',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'about_company' */ '../about_company/reducers/index'),
        loader: () => import(/* webpackChunkName: 'about_company' */ '../about_company/spaIndex'),
        documentTitle: 'О Компании',
    },
    {
        route: {
            path: '/blog-categories/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'blogs' */ '../blog_categories/reducers'),
        loader: () => import(/* webpackChunkName: 'blogs' */ '../blog_categories/spaIndex'),
        documentTitle: 'Блоги',
    },
    {
        route: {
            path: '/personnel_reserve/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'staff_reserve' */ '../staff_reserve/reducers/index'),
        loader: () => import(/* webpackChunkName: 'staff_reserve' */ '../staff_reserve/spaIndex'),
        documentTitle: 'Кадровый резерв',
    },
    {
        route: {
            path: '/foreign_employees/:locale/',
            exact: true,
        },
        reducers: () => import(/* webpackChunkName: 'foreign_employees' */'../foreign_employees/reducers/index'),
        loader: () => import(/* webpackChunkName: 'foreign_employees' */ '../foreign_employees/spaIndex'),
        documentTitle: 'ПЭК:Восток',
    },
    {
        route: {
            path: '/random-cafe/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'chat_cafe' */ '../chat_cafe/reducers/index'),
        loader: () => import(/* webpackChunkName: 'chat_cafe' */ '../chat_cafe/spaIndex'),
        documentTitle: 'Рандом Кафе',
    },
    {
        route: {
            path: '/accumulator/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'ideas_pek' */ '../ideas_pek/reducers/index'),
        loader: () => import(/* webpackChunkName: 'ideas_pek' */ '../ideas_pek/spaIndex'),
        configs: () => import(/* webpackChunkName: 'ideas_pek_configs' */ '../ideas_pek/spaConfig'),
        documentTitle: 'Генератор Идей',
    },
    {
        route: {
            path: '/franchisee/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'ideas_franchisee' */ '../ideas_pek/reducers/index'),
        loader: () => import(/* webpackChunkName: 'ideas_franchisee' */ '../ideas_pek/spaIndex'),
        configs: () => import(/* webpackChunkName: 'ideas_franchisee_configs' */ '../ideas_pek/spaConfig'),
        documentTitle: 'Генератор Идей Франчайзи',
    },
    {
        route: {
            path: ['/treasures/', '/treasures/mission/', '/treasures/values/', '/treasures/hymn/', '/treasures/strategy/', '/treasures/competencies/'],
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'treasures' */ '../treasures/reducers/index'),
        loader: () => import(/* webpackChunkName: 'treasures' */ '../treasures/spaIndex'),
        documentTitle: 'Наши сокровища',
    },
    {
        route: {
            path: '/polls/poll_reports/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'slider_poll' */ '../slider_poll/reducers/index'),
        loader: () => import(/* webpackChunkName: 'slider_poll' */ '../slider_poll/spaIndex'),
        documentTitle: 'Опросы',
    },
    {
        route: {
            path: '/votes-pek/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'votes_pek' */ '../votes_pek/reducers/index'),
        loader: () => import(/* webpackChunkName: 'votes_pek' */ '../votes_pek/spaIndex'),
        configs: () => import(/* webpackChunkName: 'votes_pek_configs' */ '../votes_pek/spaConfig'),
        documentTitle: 'Голосования и конкурсы ПЭК',
    },
    {
        route: {
            path: '/previews/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'pek_teasers' */'../pek_teasers/reducers/index'),
        loader: () => import(/* webpackChunkName: 'pek_teasers' */ '../pek_teasers/spaIndex'),
        documentTitle: 'Все видео',
    },
    {
        route: {
            path: '/video-chat/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'video_chat' */'../video_chat/reducers/index'),
        loader: () => import(/* webpackChunkName: 'video_chat' */ '../video_chat/spaIndex'),
        documentTitle: 'Видео-чат',
    },
    {
        route: {
            path: '/kod-zaboty/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'care_pek' */ '../care_pek/reducers'),
        loader: () => import(/* webpackChunkName: 'care_pek' */ '../care_pek/spaIndex'),
        configs: () => import(/* webpackChunkName: 'care_pek_configs' */ '../care_pek/spaConfig'),
        documentTitle: 'Код заботы ПЭК',
    },
    {
        route: {
            path: '/pecocracy/',
            exact: false,
        },
        reducers: () => import(/* webpackChunkName: 'pecocracy' */ '../pecocracy/reducers'),
        loader: () => import(/* webpackChunkName: 'pecocracy' */ '../pecocracy/spaIndex'),
        configs: () => import(/* webpackChunkName: 'pecocracy_configs' */ '../pecocracy/spaConfig'),
        documentTitle: 'ПЭКократия',
    },
];
