import PropTypes from 'prop-types';

export const searchContexts = [
    {
        label: 'Везде',
        labelDative: 'интранету',
        value: 'q',
    },
    {
        label: 'Пользователи',
        labelDative: 'пользователям',
        value: 'users.userprofile',
    },
    {
        label: 'Идеи',
        labelDative: 'идеям',
        value: 'ideas_tracker.idea',
    },
    {
        label: 'Блоги',
        labelDative: 'блогам',
        value: 'blog.entry',
    },
    {
        label: 'Объявления',
        labelDative: 'объявлениям',
        value: 'blog.advertentry',
    },
];

export const headerHeight = 58;

export const selectLocaleFlagStyles = {
    width: 16,
    height: 11,
    background: 'url("/static/src/common/components/PhoneInput/flags.png")',
};

export const changeLocale = locale => `/language/${locale}`;

const MenuItemPropTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    perm: PropTypes.string,
    image: PropTypes.string,
    feature: PropTypes.bool,
    newtab: PropTypes.bool,
};

export const MenuPropTypes = {
    name: PropTypes.string,
    url: PropTypes.string,
    perm: PropTypes.string,
    image: PropTypes.string,
    feature: PropTypes.bool,
    includes_submenu: PropTypes.bool,
    newtab: PropTypes.bool,
    submenu_items: PropTypes.arrayOf(PropTypes.shape(MenuItemPropTypes)).isRequired,
};
