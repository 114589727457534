import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import moment from 'moment';
import 'moment/locale/tg';
import 'moment/locale/uz';
import { A } from 'intdev-ui';
import { locale } from '@/common/localization/dictionary';
import { notificationShape } from '../constants/propTypes';
import { linkStyles } from '../../common/components/ALink';
import { dispatchMetricsEvent } from '../../common/helpers/metrics';
import { markRead } from '../actions/notifications';
import { CONTENT_STATUSES, NOTIFICATION_STATUSES } from '../constants';


class NotificationBoxComponent extends React.Component {
    static propTypes = {
        notification: notificationShape.isRequired,
        markRead: PropTypes.func.isRequired,
        createdAt: PropTypes.bool,
    };

    static defaultProps = {
        createdAt: false,
    };

    get uid() {
        const { notification } = this.props;
        return notification.id || notification.broadcastId;
    }

    get header() {
        const { notificationContent } = this.props.notification;

        let initiatorBlock = (
            <span className="notification-box__initiator">
                { notificationContent.initiator }
            </span>
        );

        if (notificationContent.initiatorUrl) {
            initiatorBlock = (
                <A href={ notificationContent.initiatorUrl } hoveredStyle={ linkStyles.accentHovered }>
                    { initiatorBlock }
                </A>
            );
        }

        return (
            <div className="notification-box__header">
                <div>
                    { initiatorBlock }
                    { notificationContent.initiator && ' ' }
                    <div className="notification-content-title">{ notificationContent.title }</div>
                </div>
            </div>
        );
    }

    get text() {
        const { notificationContent } = this.props.notification;

        let icon;
        if (notificationContent.titleIcon && notificationContent.titleIcon in CONTENT_STATUSES) {
            const label = CONTENT_STATUSES[notificationContent.titleIcon];
            icon = (
                <span className={ label.className }>
                    { label.text }
                </span>
            );
        }

        const textShown = notificationContent.text?.trim().length ? notificationContent.text : 'Посмотреть';

        return (
            <div className="notification-box__text">
                { icon }
                <A
                    href={ notificationContent.url }
                    onClick={ this.handleMarkRead }
                    hoveredStyle={ linkStyles.accentHovered }
                >
                    { textShown }
                </A>
            </div>
        );
    }

    // get markReadBlock() {
    //     return (
    //         <div className="notification-box__close">
    //             <div
    //                 onClick={ this.handleMarkRead }
    //                 title="Отметить как просмотроенное"
    //                 className="notification-box__mark-read__single"
    //             >
    //                 <i className="fa fa-check fa-check-clickable" />
    //             </div>
    //         </div>
    //     );
    // }

    handleMarkRead = () => {
        dispatchMetricsEvent('notifications-mark-single-read');
        this.props.markRead(this.uid);
    };

    render() {
        const { notification, createdAt } = this.props;
        const { notificationContent } = notification;

        if (notificationContent.notificationType !== 'generic_template') {
            return null;
        }

        const isNew = notification.status !== NOTIFICATION_STATUSES.STATUS_READ;
        const notificationBoxClassName = isNew
            ? 'notification-box notification-box__new'
            : 'notification-box';

        const handleReadNewNotification = () => isNew && this.handleMarkRead();

        return (
            <div className={ notificationBoxClassName } onClick={ handleReadNewNotification }>
                <A
                    href={ notificationContent.initiatorUrl || notificationContent.url }
                    className="notification-box__pic"
                >
                    <div style={ { backgroundImage: `url(${notificationContent.picUrl})` } } />
                </A>
                <div className="notification-box__body">
                    { this.header }
                    { this.text }
                    <div className="notification-box__date">
                        { createdAt
                            ? `${moment(notification.createdAt).locale('ru').format('D MMM')} в 
                            ${moment(notification.createdAt).format('HH:mm')}`
                            : moment.min(moment(notification.createdAt), moment()).locale(locale).fromNow() }
                    </div>
                </div>
                { isNew && this.markReadBlock }
            </div>
        );
    }
}


const mapDispatchToProps = {
    markRead,
};

export const NotificationBox = connect(
    null,
    mapDispatchToProps,
)(NotificationBoxComponent);
