import { createSelector } from 'reselect';

const headerReducerSelector = state => state.headerReducer;

const notificationSystemSelector = state => headerReducerSelector(state).notificationSystem;

const headerSelector = state => headerReducerSelector(state).header;

const notificationsCountSelector = state => state.notifications.newNotificationsCount;

export const headerMenuSelector = state => state.headerReducer.menu;

export const weHeaderSelector = createSelector(
    notificationSystemSelector,
    notificationsCountSelector,
    headerSelector,
    (notifications, newNotificationsCount, header) => {
        const {
            headerIsShown, headerImage, navigationMenuBackgroundColor, menuItemTextColor, menuBottomCellBackground,
        } = header;
        return {
            notifications,
            newNotificationsCount,
            headerImageUrl: headerImage,
            headerIsShown,
            menuBackgroundColor: navigationMenuBackgroundColor,
            menuItemTextColor,
            menuCellBackground: menuBottomCellBackground,
        };
    },
);
