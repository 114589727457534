import React from 'react';
import PropTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';
import { A } from 'intdev-ui';
import { dictionary } from '@/common/localization/dictionary';
import { SettingsIcon } from '@/services_common/components/SettingsIcon';
import { notificationShape } from '../constants/propTypes';
import { dispatchMetricsEvent } from '../../common/helpers/metrics';
import {
    loadNotifications,
    markRead,
    markReadAll,
    markSeenAll,
} from '../actions/notifications';
import { applicationUrls } from '../constants/apiUrls';
import { NotificationBox } from './NotificationBox';


class NotificationsContainerComponent extends React.Component {
    static propTypes = {
        notifications: PropTypes.arrayOf(notificationShape).isRequired,
        loadNotifications: PropTypes.func.isRequired,
        markSeenAll: PropTypes.func.isRequired,
        markReadAll: PropTypes.func.isRequired,
        newNotificationsCount: PropTypes.number.isRequired,
        unreadNotificationsCount: PropTypes.number.isRequired,
        closeBell: PropTypes.func,
    };

    static defaultProps = {
        closeBell: () => {},
    };

    componentDidMount() {
        this.props.loadNotifications();
        dispatchMetricsEvent('notifications-open-bell'); // компонент замаунтился, значит нажали на колокол
        document.addEventListener('click', this.handleATagClick);
    }

    componentDidUpdate = () => {
        const { notifications, newNotificationsCount } = this.props;
        if (newNotificationsCount > 0 && notifications.length > 0) {
            this.props.markSeenAll(notifications[0].date);
        }
    };

    componentWillUnmount() {
        document.removeEventListener('click', this.handleATagClick);
    }

    get delimiter() {
        const { notifications } = this.props;
        return notifications.length === 0 && (
            <div className="notification-container-delimiter">
                { dictionary.noNotifications }
            </div>
        );
    }

    get header() {
        const { unreadNotificationsCount } = this.props;

        let content;
        if (unreadNotificationsCount > 0) {
            content = (
                <div
                    className="notification-container-delimiter__button"
                    onClick={ this.handleMarkReadAll }
                >
                    { dictionary.markAllAsReserved }
                </div>
            );
        }

        return content && (
            <div className="notification-container-delimiter">
                { content }
            </div>
        );
    }

    handleATagClick = (event) => {
        const aTag = event.target.closest('a');
        // поскольку ссылку может быть как в картинке уведомления, так и тексте,
        // это самый просто вариант отслеживания "клика" по уведомлению
        if (aTag && aTag.getAttribute('href') !== applicationUrls.settings) {
            dispatchMetricsEvent('notifications-click');
        }
    };

    handleMarkReadAll = () => {
        const { notifications, unreadNotificationsCount } = this.props;
        if (unreadNotificationsCount > 0) {
            const date = notifications.length > 0 && notifications[0].date;
            dispatchMetricsEvent('notifications-mark-all-read');
            this.props.markReadAll(date);
        }
    };

    renderNotifications = notifications => (
        notifications.map(notification => (
            <NotificationBox
                key={ notification.id || notification.uuid }
                notification={ notification }
            />
        ))
    );

    render() {
        const { notifications, unreadNotificationsCount } = this.props;

        return (
            <div className="notifications-container">
                <div className="notifications-container-header">
                    <span className="notifications-container-header__title">{ dictionary.notifications }</span>
                    <div>
                        <A
                            href={ applicationUrls.settings }
                            onMouseOver={ this.handleHoverGear }
                            onMouseOut={ this.handleHoverGear }
                            title="Настройки уведомлений"
                            onClick={ this.props.closeBell }
                        >
                            <SettingsIcon withAnimation />
                        </A>
                    </div>
                </div>
                <div className="notifications-list">
                    { this.delimiter }
                    { this.header }
                    { this.renderNotifications(notifications.slice(0, unreadNotificationsCount)) }
                    { this.renderNotifications(notifications.slice(unreadNotificationsCount)) }
                    <a
                        href="/notification_center/"
                        className="notifications-show-all-button"
                        onClick={ this.props.closeBell }
                        style={ { textDecoration: 'none' } }
                    >
                        {dictionary.showAllNotifications}
                    </a>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    notifications: state.notifications.notifications.slice(0, state.notifications.pageSize),
    newNotificationsCount: state.notifications.newNotificationsCount,
    unreadNotificationsCount: state.notifications.unreadNotificationsCount,
});

const mapDispatchToProps = {
    loadNotifications,
    markSeenAll,
    markReadAll,
    markRead,
};

export const NotificationsContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(NotificationsContainerComponent);
