import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { connect } from 'react-redux';

import JiraTaskButton from '../../jira/jira_task/JiraTaskButton';
import './ReportScrollStyles.css';

const styles = {
    buttonStyle: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        minWidth: '130px',
        maxWidth: '130px',
        marginRight: '10px',
    },
    buttonLabelStyle: {
        padding: '0',
        fontSize: '13px',
    },
};

const arrowTop = (
    <svg width="24" height="24" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M10.7071 13.2071c-.3905.3905-1.02368.3905-1.41421 0l-4.5-4.49999c-.39052-.39053-.39052-1.02369 0-1.41422l4.5-4.5c.39053-.39052 1.02371-.39052 1.41421 0 .3905.39053.3905 1.02369 0 1.41422L6.91421 8l3.79289 3.7929c.3905.3905.3905 1.0237 0 1.4142z" />
    </svg>
);

export class ReportNScrollComponent extends React.Component {
    static propTypes = {
        minScroll: PropTypes.number,
        showReportButton: PropTypes.bool,
    };

    static defaultProps = {
        minScroll: 500,
        showReportButton: true,
    };

    componentDidMount() {
        document.addEventListener('scroll', this.handleScrollVisibility);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.handleScrollVisibility);
    }

    handleScrollVisibility = () => {
        const { minScroll } = this.props;
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop; // ie11 compat
        if (scrollTop > minScroll) {
            this.scrollBtn.classList.add('go-top-active');
        } else {
            this.scrollBtn.classList.remove('go-top-active');
        }
    };

    handleScrollTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    render() {
        const { showReportButton } = this.props;
        const goTopClassname = classnames({
            'go-top': 1,
            'go-top__button-hidden': !showReportButton,
        });

        return (
            <div className="report-scroll__root-container">
                { showReportButton && (
                    <JiraTaskButton
                        buttonStyle={ styles.buttonStyle }
                        buttonLabelStyle={ styles.buttonLabelStyle }
                        buttonLabel="Нашли ошибку?"
                        optionsListHeader="Выбери тип проблемы"
                        buttonShortname="jira_button_report_error"
                        primary={ false }
                        metricsEventName="report-error-open"
                    />
                ) }
                <div
                    className={ goTopClassname }
                    onClick={ this.handleScrollTop }
                    ref={ (ref) => { this.scrollBtn = ref; } }
                >
                    { arrowTop }
                </div>
            </div>
        );
    }
}

const mapDispatchToProps = ({ page }) => ({
    showReportButton: page && page.showReportButton,
});

export const ReportNScroll = connect(mapDispatchToProps)(ReportNScrollComponent);
