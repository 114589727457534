import React from 'react';
import PropTypes from 'prop-types';
import { IUIPropTypes } from 'intdev-ui';

const styles = {
    iconStyle: {
        flex: '1 1 auto',
    },
    styleBase: {
        display: 'flex',
        textAlign: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        width: '32px',
        height: '32px',
        borderRadius: '2px',
        transition: 'background 0.2s ease-in-out',
    },
    styleHover: {
        transition: 'background 0.2s ease-in',
        background: '#ebebeb',
    },
    styleClick: {
        transition: 'background 0s',
        background: '#e1e1e1',
    },
    styleDisabled: {
        background: '#f0f0f0',
        opacity: '0.48',
        cursor: 'auto',
    },
};

export default class IconFaButton extends React.Component {
     static propTypes = {
         /*
         * FontAwesome class(es)
         * eg.:
         * 'fa-minus-circle'
         * 'fa-spinner fa-spin'
         * */
         iconClass: PropTypes.string,
         children: PropTypes.oneOfType([
             PropTypes.arrayOf(PropTypes.node),
             PropTypes.node,
         ]),
         onClick: PropTypes.func,
         /*
         * apply disabled style and turn off click and hover styles and actions
         * */
         disabled: PropTypes.bool,
         style: IUIPropTypes.style,
         /*
         * title (tooltip) for button
         * */
         title: PropTypes.string,
         /*
         * style to set on hover
         * */
         styleHover: IUIPropTypes.style,
         /*
         * style to be set in case of disabled is True
         * */
         styleDisabled: IUIPropTypes.style,
         /*
         * performs transition from styleClick to styleHover on click
         * */
         styleClick: IUIPropTypes.style,
     };

     static defaultProps = {
         onClick: () => {},
         disabled: false,
         style: {},
         title: null,
         iconClass: null,
         children: undefined,
         styleHover: {},
         styleDisabled: {},
         styleClick: {},
     };

     state = {
         clicked: false,
         hovered: false,
     };

     render() {
         const style = {
             ...styles.styleBase,
             ...this.props.style,
             ...(this.props.disabled ? { ...styles.styleDisabled, ...this.props.styleDisabled } : {}),
             ...(this.state.hovered ? { ...styles.styleHover, ...this.props.styleHover } : {}),
             ...(this.state.hovered && this.state.clicked ? { ...styles.styleClick, ...this.props.styleClick } : {}),
         };

         let icon;
         if (this.props.iconClass) {
             icon = (
                 <i
                     title={ this.props.title }
                     className={ `fa ${this.props.iconClass}` }
                     style={ styles.iconStyle }
                 />
             );
         } else {
             icon = this.props.children;
         }

         return (
             <div
                 style={ style }
                 onMouseOver={ () => !this.props.disabled && this.setState({ hovered: true }) }
                 onMouseDown={ () => !this.props.disabled && this.setState({ clicked: true }) }
                 onMouseOut={ () => this.setState({ hovered: false, clicked: false }) }
                 onMouseUp={ () => this.setState({ clicked: false }) }
                 onClick={ e => !this.props.disabled && this.props.onClick(e) }
             >
                 { icon }
             </div>
         );
     }
}
