import React from 'react';
import { showErrorNotification } from '../../../helpers/showNotification';
import { apiUrls } from '../constants/apiUrls';
import { callApi } from '../../../middlewares/apiMiddleware';


export default function withDefaultParams(
    WrappedComponent, { permissions = [], needSelfProfile = false, needConstants = {}, features = [] },
) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                userPermissions: {
                    isLoaded: false,
                    permissions: permissions.reduce((o, k) => Object.assign(o, { [k]: false }), {}),
                },
                selfProfile: {},
                projectFeatures: features.reduce((o, k) => Object.assign(o, { [k]: false }), {}),
                projectConstants: Object.keys(needConstants).reduce(
                    (o, type) => Object.assign(o, { [type]: needConstants[type].reduce(
                        (innerObject, k) => Object.assign(innerObject, { [k]: null }), {},
                    ) }), {},
                ),
            };
        }
        componentDidMount() {
            if (permissions.length) {
                this.loadPermissions();
            }
            if (needSelfProfile) {
                this.loadSelfProfile();
            }
            if (Object.keys(needConstants).length) {
                this.loadConstants();
            }
            if (features.length) {
                this.loadFeatures();
            }
        }

        loadPermissions = () => {
            callApi(apiUrls.loadPermissions(permissions)).then((data) => {
                this.setState({
                    userPermissions: {
                        permissions: data.permissions,
                        isLoaded: true,
                    },
                });
            }).catch(() => showErrorNotification('Не удалось зарузить права пользователя'));
        };

        loadSelfProfile = () => {
            callApi(apiUrls.loadSelfProfileUrl).then((data) => {
                this.setState({
                    selfProfile: data,
                });
            }).catch(() => showErrorNotification('Не удалось зарузить профиль'));
        };

        loadConstants = () => {
            this.setState({
                projectConstants: {
                    ...this.state.projectConstants,
                    isFetching: true,
                },
            });
            const url = apiUrls.loadConstants({
                settings: needConstants.settings,
                dbOptions: needConstants.db_options,
            });
            callApi(url).then((data) => {
                this.setState({
                    projectConstants: {
                        ...data,
                        isFetching: false,
                    },
                });
            }).catch(() => {
                showErrorNotification('Не удалось зарузить данные');
                this.setState({
                    projectConstants: {
                        ...this.state.projectConstants,
                        isFetching: false,
                    },
                });
            });
        };

        loadFeatures = () => {
            callApi(apiUrls.loadProjectFeaturesUrl(features)).then((data) => {
                this.setState({
                    projectFeatures: data.features,
                });
            }).catch(() => showErrorNotification('Не удалось зарузить данные'));
        };

        render() {
            return <WrappedComponent { ...this.state } { ...this.props } />;
        }
    };
}

/* Example:

export default withDefaultParams(SingleTimelineRecord, {
    permissions: ['users.show_user_reports'],
    features: ['calls'],
    needSelfProfile: true,
    needConstants: { settings: ['JIRA_URL'], db_options: ['company_name'] },
});

class SingleTimelineRecord extends React.Component {
    static propTypes = {
        userPermissions: PropTypes.shape({
            isLoaded: PropTypes.bool,
            permissions: PropTypes.shape({
                'users.show_user_reports': PropTypes.bool,
            }),
        }),
        projectFeatures: PropTypes.shape({
            calls: PropTypes.bool,
        }),
        selfProfile: PropType.shape({
            username: PropTypes.string,
            ...
        }),
        projectConstants: PropTypes.shape({
            isFetching: PropTypes.bool,
            settings: PropTypes.shape({
                JIRA_URL: PropTypes.string,
            }),
            db_options: PropTypes.shape({
                company_name: PropTypes.string,
            }),
        }),
    }
}

*/
